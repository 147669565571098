import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

const routes: Routes = [
    {
        path: 'auth/callback/:provider/:state',
        component: AuthCallbackComponent,
    },
    {
        path: 'auth/callback/:provider',
        component: AuthCallbackComponent,
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
