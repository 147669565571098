import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../core/user.service';
import { CustomValidators } from '../_helpers/custom-validators';

declare var fillDiv: any;

export class NewPasswordUser {
    username: string;
    existingPassword: string;
    password: string;
}
@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit, OnDestroy {

    registrationUser: NewPasswordUser;

    private queryParams: any;
    email: string;
    password: string;

    newForm: UntypedFormGroup;

    loading = false;
    submitted = false;
    failed = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
        this.email = localStorage.getItem('email');
        this.password = localStorage.getItem('password');
        if (!this.email || !this.password) {
            this.router.navigate(['/login']);
        }
        this.registrationUser = new NewPasswordUser();
        this.registrationUser.username = localStorage.getItem('email');
        this.registrationUser.existingPassword = localStorage.getItem('password');
        localStorage.removeItem('email');
        localStorage.removeItem('password');

        this.newForm = this.formBuilder.group({
            password: [
                '',
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(/\d/, {
                        hasNumber: true
                    }),
                    CustomValidators.patternValidator(/[A-Z]/, {
                        hasCapitalCase: true
                    }),
                    CustomValidators.patternValidator(/[a-z]/, {
                        hasSmallCase: true
                    }),
                    Validators.minLength(8)
                ])
            ],
            confirmPassword: ['', Validators.required],
        }, {
            validator: CustomValidators.passwordMatchValidator
        });

        fillDiv();
        setTimeout(() => fillDiv());
    }

    ngOnDestroy() {}

    public hasError(controlName: string, errorName: string): boolean {
        return this.newForm.controls[controlName].hasError(errorName);
    }

    get f(): any { return this.newForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.newForm.invalid) {
            console.log(this.newForm);
            return;
        }
        if (this.loading) {
            return;
        }

        this.registrationUser.password = this.f.password.value;

        this.loading = true;
        this.userService.newPassword(this.registrationUser).subscribe(
            () => {
                console.log("password change complete");
                this.router.navigate(['/login']);
            },
            error => {
                console.error("password change failed: " + error);
                this.loading = false;
                this.failed = true;
            },
        );

    }

}
