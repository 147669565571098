import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf, iif } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Organization } from './organization';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    private readonly endpointUrl = environment.apiBaseUrl + '/organization';
    private organization: Organization;
    private availableOrganizations: [Organization];

    constructor(
        private http: HttpClient,
    ) { 
        // 前回切り替えていた組織の情報を取得
        const org = JSON.parse(sessionStorage.getItem('organization'));
        if (org) {
            this.organization = org;
            this.setCurrent(this.organization);
        }
    }

    /**
     * Helper function fetching organizations from API
     */
    private getOrganizations(related: boolean = false, organizationId: number = 0, includeHoldings: boolean = false): Observable<[Organization]> {
        let endpointUrl = this.endpointUrl;
        if (related) {
            endpointUrl = endpointUrl + '?related=1';
            if (organizationId > 0) {
                endpointUrl = endpointUrl + '&organization_id=' + organizationId;
            }
        }
        if (includeHoldings) {
            endpointUrl = endpointUrl + (related ? '&' : '?') + 'include_holdings=1';
        }
        return this.http.get(endpointUrl).pipe(map(result => {
            this.availableOrganizations = result as [Organization];
            if (this.availableOrganizations && this.availableOrganizations.length) {
                // if previous organization exist, try to find in fetched available organizations
                if (this.organization) {
                    this.organization = this.availableOrganizations.find(o => this.organization && this.organization.id === o.id);
                }
                // if organization not exist, take the first available
                if (!this.organization) {
                    this.organization = this.availableOrganizations[0];
                }
            } else {
                this.organization = undefined;
            }
            return this.availableOrganizations;
        }));
    }

    public current(forceLoad: boolean = false, includeHoldings: boolean = false): Observable<Organization> {
        return (!this.organization || forceLoad)
            ? this.getOrganizations(false, 0, includeHoldings).pipe(map(() => this.organization))
            : observableOf(this.organization);
    }

    public hasCurrent(): Observable<Organization | undefined> {
        return observableOf(this.organization);
    }

    public setCurrent(organization: Organization): boolean {
        const available = this.availableOrganizations;
        const valid = available && available.length && available.some(o => o.id === organization.id);
        this.organization = (valid) ? organization : this.organization;
        return valid;
    }

    public update(data: Organization, forceLoad: boolean = false): Observable<Organization | undefined> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.put(this.endpointUrl, data, options).pipe(
            switchMap(
                result => {
                    const success = result && true && Object.assign<Organization, Organization>(this.organization, data);
                    return iif(() => success && true, this.current(forceLoad), observableOf(undefined));
                },
            )
        );
    }

    public available(forceLoad: boolean = false, includeHoldings: boolean = false): Observable<[Organization]> {
        return (!this.organization || forceLoad)
            ? this.getOrganizations(false, 0, includeHoldings).pipe(map(() => this.availableOrganizations))
            : observableOf(this.availableOrganizations);
    }

    public reloadOrganizations(includeHoldings: boolean = false): Observable<[Organization]> {
        return this.getOrganizations(false, 0, includeHoldings).pipe(map(() => this.availableOrganizations));
    }

    public relatedOrganizations(related: boolean = false, organizationId: number = 0): Observable<[Organization]> {
        return this.getOrganizations(related, organizationId).pipe(map(() => this.availableOrganizations));
    }
}
