import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, iif, of as observableOf, forkJoin, EMPTY } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OrganizationService } from '../core/organization.service';
import { Organization } from '../core/organization';
import { AuthService } from './auth.service';
import { Token } from './token';

const ORGANIZATION_HEADER = 'Aifr-Organization';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private organizationService: OrganizationService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.isTokenExpired().pipe(
            switchMap(isExpired => {
                if (isExpired) {
                    this.authService.logout();
                    this.router.navigate(['/login']);
                    return EMPTY;
                } else {
                    // トークンが有効な場合は、通常のインターセプト処理を行う
                    return iif(
                        () => request.url.startsWith(environment.apiBaseUrl),
                        forkJoin({
                            token: this.authService.getToken(),
                            organization: this.organizationService.hasCurrent(),
                        }).pipe(
                            map(data => this.authorizeRequest(request, data.token, data.organization)),
                        ),
                        observableOf(request)
                    ).pipe(
                        switchMap(requestItem => next.handle(requestItem)),
                        catchError(res => {
                            if (res.status === 0 && request.url.startsWith(environment.apiBaseUrl)) {
                                return this.authService.tokenFromRefreshToken().pipe(
                                    switchMap(() => this.authService.getToken()),
                                    map(token => this.authorizeRequest(request, token)),
                                    switchMap(requestItem => next.handle(requestItem)),
                                );
                            } else {
                                return throwError(res);
                            }
                        })
                    );
                }
            })
        );
    }

    private authorizeRequest(request: HttpRequest<any>, token: Token, organization?: Organization): HttpRequest<any> {
        if (token && token.idToken) {
            let headers = request.headers.set('Authorization', token.idToken);
            // if organizations exist, add organization ID as custom request header
            if (organization) {
                headers = headers.set(ORGANIZATION_HEADER, '' + organization.id);
            }
            const requestItem = request.clone({
                headers
            });
            return requestItem;
        }
        return request;
    }
}