import { Injectable } from '@angular/core';
import { Papa, ParseConfig } from 'ngx-papaparse';
import { ConvertType } from './convert-type';

@Injectable()
export class DataConverterService {

    constructor(
        private papa: Papa,
    ) { }

    convertFrom(source: any, type: ConvertType): string {
        let result;
        if (type === ConvertType.Json) {
            result = JSON.stringify(source);
        } else if (type === ConvertType.Csv) {
            result = this.papa.unparse(source);
        }
        return result;
    }

    convertTo<T>(csv: string | File, config?: ParseConfig): T | undefined {
        const parsedData = this.papa.parse(csv, config);
        if (parsedData !== undefined) {
            const result = parsedData.data as T;
            return result;
        }
    }
}
