<div class="loading-shade" *ngIf="loading">
  <mat-spinner [diameter]="70" *ngIf="loading"></mat-spinner>
</div>
<div class="container">
  <div class="vertical-center">
    <p class="title">{{ 'mfa.app.title' | translate }}</p>
    <div class="content">
      <p class="subtitle">{{ 'mfa.app.section1' | translate }}</p>
      <p class="subtitle">{{ 'mfa.app.detail1' | translate }}</p>
      <p class="subtitle">{{ 'mfa.app.section2' | translate }}</p>
      <div class="qr-code" #canvas></div>
      <p class="subtitle">{{ 'mfa.app.section3' | translate }}</p>

      <form [formGroup]="formGroup" class="form" (ngSubmit)="onSubmit()">
        <input matInput
          type="string"
          formControlName="code"
          class="form-control input-code"
          placeholder="{{ 'mfa.app.placeholder' | translate }}"
          maxlength="6"
          [ngClass]="{ 'is-invalid': submitted && (f.code.errors || failed) }"
        >
        <div class="code-error" [style]="errorClass">
          <div *ngIf="submitted && f.code.errors && f.code.errors.required">{{ 'mfa.app.required' | translate }}</div>
          <div *ngIf="submitted && f.code.errors && !f.code.errors.required">{{ 'mfa.app.inputError' | translate }}</div>
          <div *ngIf="submitted && failed">{{ 'mfa.app.mismatch' | translate }}</div>
        </div>
        <div class="submit-area">
          <button mat-button type="submit" class="btn-submit">
            <p class="btn-title">{{ 'mfa.app.activation' | translate }}</p>
          </button>
          <a class="link-cancel" href="/mfa/select">{{ 'common.cancel' | translate }}</a>
        </div>
      </form>
    </div>
  </div>
</div>
