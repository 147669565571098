export enum AuthProvider {
    None = '',
    Cognito = 'cognito',
    Google = 'google',
    SlackUser = 'slack',
    SlackAdmin = 'slackgeneral',
    Akerun = 'akerun',
    LineWorks = 'lineworks',
    MicrosoftEntraId = 'entraid'
}
