import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../core/user.service';
import { User } from '../../core/user';
import { TranslateService } from '@ngx-translate/core';
import { PlanService } from '../../core/credit.service';

export interface Tile {
    title: string;
    detail: string;
    link: string;
    icon: string;
    isDisabled?: boolean;
}

@Component({
    selector: 'app-usage',
    templateUrl: './usage.component.html',
    styleUrls: ['./usage.component.scss']
})
export class UsageComponent implements OnInit, OnDestroy {

    private usersSubscription: Subscription;
    private planSubscription: Subscription;

    user: User;
    
    basicTiles: Tile[] = [
        {title: 'sidebar.manual', detail: 'home.usage.detail.manual', link: 'https://raku-neko.jp/manual/', icon: 'manual'},
        {title: 'sidebar.member.accountSettings', detail: 'home.usage.detail.accountSettings', link: 'account_setting', icon: 'account'},
        {title: 'sidebar.member.appointments', detail: 'home.usage.detail.appointments', link: 'appointment', icon: 'appointment'},
        {title: 'sidebar.member.visitorHistories', detail: 'home.usage.detail.visitorHistories', link: 'visitor_history', icon: 'visitor_history'},
        {title: 'sidebar.member.locationReservation', detail: 'home.usage.detail.locationReservation', link: 'location_reservation', icon: 'location_reservation'},
        {title: 'sidebar.member.calendar-extension', detail: 'home.usage.detail.calendar-extension', link: 'calendar-extension', icon: 'calendar'},
    ];

    adminTiles: Tile[] = [
        {title: 'sidebar.admin.receptionSettings', detail: 'home.usage.detail.receptionSettings', link: 'organization_setting', icon: 'setting'},
        {title: 'sidebar.admin.users', detail: 'home.usage.detail.users', link: 'user_setting', icon: 'user_setting'},
        {title: 'sidebar.admin.organizationManagement', detail: 'home.usage.detail.organizationManagement', link: 'organization_management', icon: 'organization_management'},
        {title: 'sidebar.admin.offices', detail: 'home.usage.detail.offices', link: 'office', icon: 'office'},
        {title: 'sidebar.admin.divisions', detail: 'home.usage.detail.divisions', link: 'division', icon: 'division'},
        {title: 'sidebar.admin.deviceManagement', detail: 'home.usage.detail.deviceManagement', link: 'device_management', icon: 'ipad'},
    ];

    rakunekoSeriesTiles: Tile[] = [
        {title: 'sidebar.admin.meetingRooms', detail: 'home.usage.detail.meetingRooms', link: 'location', icon: 'location'},
        {title: 'sidebar.admin.supplies', detail: 'home.usage.detail.supplies', link: 'supply', icon: 'supply'},
        {title: 'sidebar.member.events', detail: 'home.usage.detail.events', link: 'event', icon: 'event'},
    ];

    otherTiles: Tile[] = [
        {title: 'sidebar.admin.externalLinkage', detail: 'home.usage.detail.externalLinkage', link: 'external_linkage', icon: 'external'},
        {title: 'sidebar.admin.creditCard', detail: 'home.usage.detail.creditCard', link: 'plan', icon: 'plan'},
        {title: 'sidebar.contact', detail: 'home.usage.detail.contact', link: environment.rakunekoContactUri, icon: 'contact'},
    ];

    constructor(
        public userService: UserService,
        public planService: PlanService,
        private readonly translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.loadUser();
        this.loadOrganizationContract();
    }

    ngOnDestroy() {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
        if (this.planSubscription) {
            this.planSubscription.unsubscribe();
        }
    }

    private loadUser() {
        this.usersSubscription = this.userService.current(false).subscribe(
            user => {
                this.user = user;

                // ユーザーが一般の場合は表示しないメニューの制御
                this.userService.isAdmin.subscribe(isAdmin => {
                    if (!isAdmin) {

                        const removeTargets = [
                            'sidebar.admin.meetingRooms',
                            'sidebar.admin.supplies'
                        ];
                        this.rakunekoSeriesTiles = this.rakunekoSeriesTiles.filter((tile) => {
                            return !removeTargets.includes(tile.title);
                        });
                    }
                })
            }
        );
    }

    private loadOrganizationContract() {
        this.planSubscription = this.planService.contractGet().subscribe(
            entities => {
                if (entities.payment_method !== 'credit_card') {
                    // 契約管理テーブルにレコードがない場合（旧来の契約形態の顧客）はプラン関連のメニューを表示しない
                    const removeTargets = [
                        'sidebar.admin.creditCard',
                        'sidebar.contact'
                    ];
                    this.otherTiles = this.otherTiles.filter((tile) => {
                        return !removeTargets.includes(tile.title);
                    });
                } else if (
                    entities.payment_method === 'credit_card' &&
                    entities.trial_end !== null &&
                    Date.now() > Date.parse(entities.trial_end) &&
                    !entities.register_credit_card
                    ) {
                    // 契約管理テーブルにレコードがあるが、トライアル期間が過ぎていて、支払方法未登録の場合に使用不可能なメニューを非活性化するフラグを設定
                    this.adminTiles = this.adminTiles.map((tile) => {
                        if (['sidebar.admin.receptionSettings'].includes(tile.title)) {
                            tile.isDisabled = true;
                            return tile;
                        }
                        return tile;
                    });

                    this.rakunekoSeriesTiles = this.rakunekoSeriesTiles.map((tile) => {
                        if (['sidebar.admin.meetingRooms', 'sidebar.member.events'].includes(tile.title)) {
                            tile.isDisabled = true;
                            return tile;
                        }
                        return tile;
                    });

                    this.otherTiles = this.otherTiles.map((tile) => {
                        if (['sidebar.admin.externalLinkage'].includes(tile.title)) {
                            tile.isDisabled = true;
                            return tile;
                        }
                        return tile;
                    });
                }
            }
        );
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }
}
