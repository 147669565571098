import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of as observableOf, iif } from 'rxjs';
import { concatMap, switchMap, expand, map, reduce, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MicrosoftEntraIdManagement } from './microsoft-entra-id-management';
import { OfficesMasterData } from './offices-master-data';

@Injectable({
    providedIn: 'root'
})

export class MicrosoftEntraIdService {

    private microsoftEntraIdManagement: MicrosoftEntraIdManagement;
    private officesMasterData: OfficesMasterData[];

    private readonly endpointUrl = environment.apiBaseUrl + '/entraid/setting';
    private readonly groupsUrl = environment.apiBaseUrl + '/entraid/setting/groups';
    private readonly officeMasterDatatUrl = environment.apiBaseUrl + '/office';

    constructor(
        private http: HttpClient,
    ) {
    }

    accessToken(code: string): Observable<MicrosoftEntraIdManagement | undefined> {
        // access code from microsoft Entra Id authorization callback
        if (code && code.length > 0) {
            const httpParams = new HttpParams()
                .append('client_id', environment.microsoftEntraIdSettings.ClientId)
                .append('grant_type', environment.microsoftEntraIdSettings.GrantType)
                .append('scope', environment.microsoftEntraIdSettings.Scope)
                .append('code', code)
                .append('redirect_uri', environment.microsoftEntraIdSettings.RedirectUri)
                .append('client_secret', environment.microsoftEntraIdSettings.ClientSecret);
        
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            };

            const endpointUrl = environment.microsoftEntraIdSettings.AccessTokenUrl;

            // レスポンス
            // {"token_type":"Bearer","scope":"Group.Read.All User.Read User.Read.All profile openid email","expires_in":4875,"ext_expir
            // es_in":4875,"access_token":"eyJ0eXAi...","refresh_token":"0.AXEAY8..."}
            // return this.http.post(this.endpointUrl, {}, options).pipe(
            return this.http.post(endpointUrl, httpParams.toString(), httpOptions).pipe(
                map((result: { token_type: string, scope: string, expires_in: number, ext_expires_in: number, access_token: string, refresh_token: string }) => {
                    const management: MicrosoftEntraIdManagement = {
                        token_type: result.token_type,
                        scope: result.scope,
                        access_token: result.access_token,
                        refresh_token: result.refresh_token,
                        expires_in: result.expires_in,
                        ext_expires_in: result.ext_expires_in
                    };
                    console.log("Microsoft Entra ID Management", management);

                    return management;
                })
            );
        }
        return observableOf(undefined);
    }

    public testAccessToken(code: string): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.endpointUrl, {code: code}, options).pipe(map(result => {
            return result;
        }));
        return observableOf(undefined);
    }

    public getGroups(): Observable<any> {
        return this.http.get(this.groupsUrl).pipe(map(result => {
            return result;
        }));
    }


    public current(forceLoad: boolean = false): Observable<MicrosoftEntraIdManagement> {
        return (!this.microsoftEntraIdManagement || forceLoad) ? this.http.get(this.endpointUrl).pipe(map(result => {
            this.microsoftEntraIdManagement = result as MicrosoftEntraIdManagement;
            return this.microsoftEntraIdManagement;
        })) : observableOf(this.microsoftEntraIdManagement);
    }

    public upsert(data: MicrosoftEntraIdManagement, forceLoad: boolean = false): Observable<MicrosoftEntraIdManagement | undefined> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.put(this.endpointUrl, data, options).pipe(
            map(
                result => {
                    this.microsoftEntraIdManagement = result as MicrosoftEntraIdManagement;
                    return this.microsoftEntraIdManagement;
                },
            )
        );

    }

    public managementAdd(microsoftEntraIdManagement: MicrosoftEntraIdManagement): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.endpointUrl, microsoftEntraIdManagement, options);
    }

    public managementChange(microsoftEntraIdManagement: MicrosoftEntraIdManagement, orianizationId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(this.endpointUrl + '/' + orianizationId, microsoftEntraIdManagement, options);
    }

    public managementDelete(id: number): Observable<any> {
        return this.http.delete(this.endpointUrl + '/' + id);
    }

    public officesMasterDataGet(forceLoad: boolean = false): Observable<OfficesMasterData[]> {
        return (!this.officesMasterData || forceLoad) ? this.http.get(this.officeMasterDatatUrl).pipe(map(result => {
            this.officesMasterData = result["offices"] as OfficesMasterData[];
            return this.officesMasterData;
        })) : observableOf(this.officesMasterData);
    }

}
