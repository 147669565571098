import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf, iif, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Organization } from './organization';
import { OrganizationAccessSetting } from './organization-access-setting';
import { OrganizationManagement } from './organization-management';

@Injectable({
    providedIn: 'root'
})
export class OrganizationAccessSettingService {
    private readonly endpointUrl = environment.apiBaseUrl + '/organization/restriction';
    private organization: Organization;
    private accessSettings: OrganizationAccessSetting[];
    private availableOrganizations: [Organization];

    public ipAddress = '';

    constructor(
        private http: HttpClient,
    ) { 
    }

    getClientIPAddress() {
        return this.http.get<{ip:string}>(`${this.endpointUrl}/ip`).pipe(
            catchError(error => {
                console.error('Failed to retrieve client IP address', error);
                return throwError('Failed to retrieve client IP address');
            })
        );
    }

    public check(force: boolean = false): Observable<any> {
        return this.getClientIPAddress().pipe(
            switchMap(ipData => {
                this.ipAddress = ipData.ip;
                let endpointUrl = `${this.endpointUrl}/check`;
                if (force) {
                    endpointUrl += '?force=true';
                }
                return this.http.get(endpointUrl)
            }),
            map(result => {
                return result;
            }),
            catchError(error => {
                console.error('Error during check:', error);
                return observableOf({isRestricted : false});
            })
        );
    }

    public managementGet(forceLoad: boolean = false): Observable<OrganizationAccessSetting[]> {
        return (!this.accessSettings || forceLoad) ? this.http.get(this.endpointUrl).pipe(map(result => {
            this.accessSettings = result as OrganizationAccessSetting[];
            return this.accessSettings;
        })) : observableOf(this.accessSettings);
    }

    public managementAdd(accessSetting: OrganizationAccessSetting): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.endpointUrl, accessSetting, options);
    }

    public managementChange(accessSetting: OrganizationAccessSetting, accessSettingId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(this.endpointUrl + '/' + accessSettingId, accessSetting, options);
    }

    public managementDelete(accessSettingId: number): Observable<any> {
        console.log("DELETE accessSettingId: " + accessSettingId);
        return this.http.delete(this.endpointUrl + '/' + accessSettingId);
    }
}
