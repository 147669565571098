<h4 class="title">{{ 'home.usage.titleBasic' | translate }}</h4>
<mat-grid-list cols="6" rowHeight="241px" class="grid-container">
  <mat-grid-tile *ngFor="let tile of basicTiles">
    <mat-card class="card-container">
      <mat-card-content>
        <div *ngIf="!tile.link.includes('https'); else elseBlock">
          <mat-card-title class="card-title" routerLink="/{{tile.link}}">
            <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
          </mat-card-title>
        </div>
        <ng-template #elseBlock>
          <mat-card-title class="card-title" (click)="goToLink(tile.link)">
            <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
          </mat-card-title>
        </ng-template>
        <mat-card-subtitle class="card-subtitle">{{ tile.title |  translate }}</mat-card-subtitle>
        <mat-card-subtitle class="card-detail">{{ tile.detail |  translate }}</mat-card-subtitle>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <!-- <ng-container *ngIf="userService.isAdmin | async">
    <mat-grid-tile *ngFor="let tile of adminTiles">
      <mat-card class="card-container">
        <mat-card-content>
          <div *ngIf="!tile.link.includes('https'); else elseBlock">
            <mat-card-title class="card-title" routerLink="/{{tile.link}}">
              <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
            </mat-card-title>
          </div>
          <ng-template #elseBlock>
            <mat-card-title class="card-title" (click)="goToLink(tile.link)">
              <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
            </mat-card-title>
          </ng-template>
            <mat-card-subtitle class="card-subtitle">{{ tile.title |  translate }}</mat-card-subtitle>
          <mat-card-subtitle class="card-detail">{{ tile.detail |  translate }}</mat-card-subtitle>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </ng-container> -->
</mat-grid-list>

<ng-container *ngIf="userService.isAdmin | async">
  <h4 class="title">{{ 'home.usage.titleAdmin' | translate }}</h4>
  <mat-grid-list cols="6" rowHeight="241px" class="grid-container">
    <mat-grid-tile *ngFor="let tile of adminTiles">
      <mat-card class="card-container">
        <mat-card-content>
          <div *ngIf="!tile.link.includes('https'); else elseBlock">
            <mat-card-title class="card-title" routerLink="/{{tile.link}}" [class.disabled]="tile.isDisabled ? true : null">
              <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
            </mat-card-title>
          </div>
          <ng-template #elseBlock>
            <mat-card-title class="card-title" (click)="goToLink(tile.link)">
              <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
            </mat-card-title>
          </ng-template>
            <mat-card-subtitle class="card-subtitle">{{ tile.title |  translate }}</mat-card-subtitle>
          <mat-card-subtitle class="card-detail">{{ tile.detail |  translate }}</mat-card-subtitle>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</ng-container>

<h4 class="title">{{ 'home.usage.titleRakunekoSeries' | translate }}</h4>
<mat-grid-list cols="6" rowHeight="241px" class="grid-container">
  <mat-grid-tile *ngFor="let tile of rakunekoSeriesTiles">
    <mat-card class="card-container">
      <mat-card-content>
        <div *ngIf="!tile.link.includes('https'); else elseBlock">
          <mat-card-title class="card-title" routerLink="/{{tile.link}}" [class.disabled]="tile.isDisabled ? true : null">
            <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
          </mat-card-title>
        </div>
        <ng-template #elseBlock>
          <mat-card-title class="card-title" (click)="goToLink(tile.link)">
            <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
          </mat-card-title>
        </ng-template>
          <mat-card-subtitle class="card-subtitle">{{ tile.title |  translate }}</mat-card-subtitle>
        <mat-card-subtitle class="card-detail">{{ tile.detail |  translate }}</mat-card-subtitle>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<ng-container *ngIf="userService.isAdmin | async">
<h4 class="title">{{ 'home.usage.titleOther' | translate }}</h4>
  <mat-grid-list cols="6" rowHeight="241px" class="grid-container">
    <mat-grid-tile *ngFor="let tile of otherTiles">
      <mat-card class="card-container">
        <mat-card-content>
          <div *ngIf="!tile.link.includes('https'); else elseBlock">
            <mat-card-title class="card-title" routerLink="/{{tile.link}}" [class.disabled]="tile.isDisabled ? true : null">
              <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
            </mat-card-title>
          </div>
          <ng-template #elseBlock>
            <mat-card-title class="card-title" (click)="goToLink(tile.link)">
              <img src="../../../assets/img/icons/svg/{{tile.icon}}.svg" style="width: 60px; height: 60px;" />
            </mat-card-title>
          </ng-template>
            <mat-card-subtitle class="card-subtitle">{{ tile.title |  translate }}</mat-card-subtitle>
          <mat-card-subtitle class="card-detail">{{ tile.detail |  translate }}</mat-card-subtitle>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</ng-container>
