import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf, iif } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as AWS from 'aws-sdk';
import {
    CognitoUserSession,
    CognitoUserPool,
    ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import { LineWorksManagement } from './lineworks-management';

@Injectable({
    providedIn: 'root'
})

export class LineWorksService {

    // private setting: LineWorksSetting;
    private lineworksManagement: LineWorksManagement;

    session: CognitoUserSession;
    private userPool: CognitoUserPool;
    private readonly poolData: ICognitoUserPoolData;
    private readonly endpointUrl = environment.apiBaseUrl + '/lineworks/setting';

    constructor(
        private http: HttpClient,
    ) {
        AWS.config.region = environment.cognitoSettings.Region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: environment.cognitoSettings.IdentityPoolId
        });
        this.poolData = {
            UserPoolId: environment.cognitoSettings.UserPoolId,
            ClientId: environment.cognitoSettings.ClientId,
        };
        this.userPool = new CognitoUserPool(this.poolData);
    }

    accessToken(code: string): Observable<LineWorksManagement | undefined> {
        // access code from lineworks authorization callback
        if (code && code.length > 0) {
            return this.http.get(`${this.endpointUrl}?code=${code}`).pipe(
                map((result: { access_token: string, refresh_token: string, expires_in: number }) => {
                    const management: LineWorksManagement = {
                        access_token: result.access_token,
                        refresh_token: result.refresh_token,
                        expires_in: result.expires_in
                    };

                    return management;
                })
            );
        }
        return observableOf(undefined);
    }

    public current(forceLoad: boolean = false): Observable<LineWorksManagement> {
        return (!this.lineworksManagement || forceLoad) ? this.http.get(this.endpointUrl).pipe(map(result => {
            this.lineworksManagement = result as LineWorksManagement;
            return this.lineworksManagement;
        })) : observableOf(this.lineworksManagement);
    }

    public update(data: LineWorksManagement, forceLoad: boolean = false): Observable<LineWorksManagement | undefined> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.put(this.endpointUrl, data, options).pipe(
            switchMap(
                result => {
                    const success = result && true && Object.assign<LineWorksManagement, LineWorksManagement>(this.lineworksManagement, data);
                    return iif(() => success && true, this.current(forceLoad), observableOf(undefined));
                },
            )
        );

    }

    public managementAdd(lineworksManagement: LineWorksManagement): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.endpointUrl, lineworksManagement, options);
    }

    public managementChange(lineworksManagement: LineWorksManagement, orianizationId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(this.endpointUrl + '/' + orianizationId, lineworksManagement, options);
    }

    public managementDelete(orianizationId: number): Observable<any> {
        return this.http.delete(this.endpointUrl + '/' + orianizationId);
    }
}
