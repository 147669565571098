import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AdminGuard } from '../core/admin.guard';
// import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';
import { MfaSelectComponent } from '../mfa/mfa-select/mfa-select.component';
import { MfaSmsComponent } from '../mfa/mfa-sms/mfa-sms.component';
import { MfaAppComponent } from '../mfa/mfa-app/mfa-app.component';
import { PageComponent } from './page/page.component';

const routes: Routes = [
    {
        path: '',
        component: PageComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('../dashboard/dashboard.module').then(
                    mod => mod.DashboardModule
                ),
            },
            {
                path: 'appointment',
                loadChildren: () => import('../appointment/appointment.module').then(
                    mod => mod.AppointmentModule
                ),
            },
            {
                path: 'visitor_history',
                loadChildren: () => import('../visitor-history/visitor-history.module').then(
                    mod => mod.VisitorHistoryModule
                ),
            },
            {
                path: 'event',
                loadChildren: () => import('../event/event.module').then(
                    mod => mod.EventModule
                ),
            },
            {
                path: 'location_reservation',
                loadChildren: () => import('../location-reservation/location-reservation.module').then(
                    mod => mod.LocationReservationModule
                ),
            },
            {
                path: 'account_setting',
                loadChildren: () => import('../account-setting/account-setting.module').then(
                    mod => mod.AccountSettingModule
                ),
            },
            {
                path: 'calendar-extension',
                loadChildren: () => import('../calendar-extension/calendar-extension.module').then(
                    mod => mod.CalendarExtensionModule
                ),
            },
            {
                path: 'organization_setting',
                loadChildren: () => import('../organization-setting/organization-setting.module').then(
                    mod => mod.OrganizationSettingModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'user_setting',
                loadChildren: () => import('../user-setting/user-setting.module').then(
                    mod => mod.UserSettingModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'location',
                loadChildren: () => import('../location/location.module').then(
                    mod => mod.LocationModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'office',
                loadChildren: () => import('../office/office-setting.module').then(
                    mod => mod.DivisionSettingModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'division',
                loadChildren: () => import('../division/division-setting.module').then(
                    mod => mod.DivisionSettingModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'supply',
                loadChildren: () => import('../supply/supply.module').then(
                    mod => mod.SupplyModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'device_management',
                loadChildren: () => import('../device-management/device-management.module').then(
                    mod => mod.DeviceManagementModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'external_linkage',
                loadChildren: () => import('../external-linkage/external-linkage.module').then(
                    mod => mod.ExternalLinkageModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'plan',
                loadChildren: () => import('../plan/plan.module').then(
                    mod => mod.PlanModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'organization_management',
                loadChildren: () => import('../organization-management/organization-management.module').then(
                    mod => mod.OrganizationManagementModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'change_payment_method',
                loadChildren: () => import('../change-payment-method/change-payment-method.module').then(
                    mod => mod.ChangePaymentMethodModule
                ),
                canActivate: [AdminGuard],
                runGuardsAndResolvers: 'always',
            },
        ],
    },
    {
        path: 'mfa',
        children: [
            {
                path: 'select',
                loadChildren: () => import('../mfa/mfa.module').then(
                    mod => mod.MfaModule
                ),
                component: MfaSelectComponent,
            },
            {
                path: 'sms',
                loadChildren: () => import('../mfa/mfa.module').then(
                    mod => mod.MfaModule
                ),
                component: MfaSmsComponent,
            },
            {
                path: 'app',
                loadChildren: () => import('../mfa/mfa.module').then(
                    mod => mod.MfaModule
                ),
                component: MfaAppComponent,
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PageRoutingModule { }
