import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { IdentityProvider } from './identity-provider.enum';
import { Token } from './token';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService
    ) { }

    isAuthenticated(): Observable<boolean> {
        return this.getToken().pipe(
            map(token => !!(token && token.idToken)),
        );
    }

    isTokenExpired(): Observable<boolean> {
        return this.getToken().pipe(
            map(token => {
                if (token && token.idToken) {
                    const jwtHelper = new JwtHelperService();
                    return jwtHelper.isTokenExpired(token.idToken);
                }
                return false;
            })
        );
    }

    getToken(): Observable<Token> {
        return this.localStorage.getToken();
    }

    login(identityProvider: IdentityProvider) {
        const endpointUrl = environment.cognitoSettings.CognitoUrl + '/oauth2/authorize';

        const httpParams = new HttpParams()
            .append('identity_provider', identityProvider)
            .append('redirect_uri', environment.cognitoSettings.LoginRedirectUrl)
            .append('client_id', environment.cognitoSettings.ClientId)
            .append('scope', 'email openid')
            .append('response_type', 'CODE');

        location.href = endpointUrl + '?' + httpParams.toString();
    }

    logout() {
        this.localStorage.deleteToken();
        const endpointUrl = environment.cognitoSettings.CognitoUrl + '/logout';

        const httpParams = new HttpParams()
            .append('client_id', environment.cognitoSettings.ClientId)
            .append('logout_uri', environment.cognitoSettings.LogoutRedirectUrl);

        location.href = endpointUrl + '?' + httpParams.toString();
    }

    tokenFromGoogleLogin(accessCode: string): Observable<any> {
        this.localStorage.deleteToken();

        const endpointUrl = environment.cognitoSettings.CognitoUrl + '/oauth2/token';

        const httpParams = new HttpParams()
            .append('grant_type', 'authorization_code')
            .append('client_id', environment.cognitoSettings.ClientId)
            .append('code', accessCode)
            .append('redirect_uri', environment.cognitoSettings.LoginRedirectUrl);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };
        return this.http.post(endpointUrl, httpParams.toString(), httpOptions).pipe(
            tap(
                (result) => this.localStorage.setToken(new Token(result.id_token, result.access_token, result.refresh_token))
            ),
        );
    }

    tokenFromRefreshToken(): Observable<any> {
        const endpointUrl = environment.cognitoSettings.CognitoUrl + '/oauth2/token';
        return this.localStorage.getToken().pipe(
            switchMap(token => {
                const httpParams = new HttpParams()
                    .append('grant_type', 'refresh_token')
                    .append('client_id', environment.cognitoSettings.ClientId)
                    .append('refresh_token', token.refreshToken);

                const httpHeaders = new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                });
                return this.http.post<any>(endpointUrl, httpParams.toString(), { headers: httpHeaders });
            }),
            tap(
                (result) => this.localStorage.setToken(new Token(result.id_token, result.access_token, result.refresh_token)),
            )
        );
    }
}
