import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, of as observableOf } from 'rxjs';
import { concatMap, expand, map, reduce } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SlackChannel } from './slack-channel';
import { NotAuthorizedError } from './not-authorized-error';
import { SlackSetting } from './slack-setting';

const SLACK_CHANNELS_LIMIT = '1000';

interface SlackResponse {
    ok: boolean;
    channels?: any[];
    error?: string;
    response_metadata: {
        next_cursor?: string
    }
}

@Injectable({
    providedIn: 'root'
})
export class SlackNotificationService {
    constructor(
        private http: HttpClient,
    ) { }

    authorize(state?: string) {
        const stateEncoded = (state) ? this.base64EncodeUrl(btoa(state)) : undefined;
        const httpParams = new HttpParams()
            .append('client_id', environment.slackSettings.ClientId)
            .append('scope', environment.slackSettings.Scope)
            .append('redirect_uri', this.getRedirectUri(stateEncoded));

        const slackAuthorizeUrl = environment.slackSettings.AuthorizeUrl + '?' + httpParams.toString();
        location.href = slackAuthorizeUrl;
    }

    accessToken(accessCode: string, stateEncoded?: string): Observable<SlackSetting | undefined> {
        // access code from slack authorization callback
        if (accessCode && accessCode.length > 0) {

            const httpParams = new HttpParams()
                .append('client_id', environment.slackSettings.ClientId)
                .append('client_secret', environment.slackSettings.ClientSecret)
                .append('code', accessCode)
                .append('redirect_uri', this.getRedirectUri(stateEncoded));

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                })
            };

            const endpointUrl = environment.slackSettings.AccessTokenUrl;

            return this.http.post(endpointUrl, httpParams.toString(), httpOptions).pipe(
                map((result: { team_id: string, team_name: string, access_token: string }) => {
                    const setting: SlackSetting = {
                        slack_notification_team_id: result.team_id,
                        slack_notification_team_name: result.team_name,
                        slack_access_token: result.access_token,
                        slack_notification_enabled: true,
                    };

                    return setting;
                }),
                concatMap((setting: SlackSetting) => {
                    const p = new HttpParams()
                        .append('token', setting.slack_access_token);

                    const o = {
                        headers: new HttpHeaders({
                            Accept: 'application/x-www-form-urlencoded',
                        }),
                        params: p
                    };

                    return this.http.get(environment.slackSettings.AuthTestUrl, o).pipe(
                        map((authTest: { ok: boolean, user_id: string }) => {
                            if (authTest.ok) {
                                setting.slack_mention = authTest.user_id;
                            }

                            return setting;
                        }),
                    );
                }),
            );
        }
        return observableOf(undefined);
    }

    listChannels(token: string) {

        const endpointUrl = environment.slackSettings.ApiUrl + '/conversations.list';
        const getConversationList = (channelTypeList?: Array<string>, range?: string): Observable<any> => {

            console.log('loading items with range', range);

            let httpParams = new HttpParams()
                .append('limit', SLACK_CHANNELS_LIMIT)
                .append('exclude_archived', 'true')
                .append('token', token);
            if (range) {
                httpParams = httpParams.append('cursor', range);
            }
            httpParams = httpParams.append('types', channelTypeList[0]);

            const requestUrl = endpointUrl + '?' + httpParams.toString();
            return this.http.get(requestUrl)
        };

        // 取得したいチャンネル種別リスト
        // 本来はカンマ区切りで設定すれば済むのだが、
        // スコープ追加前に認証トークンだとAPIエラーになるため個別でリクエストする
        let channelTypeList = [
            'public_channel',
            'private_channel'
        ]

        // cursorが返ってくる間はAPIリクエストを繰り返す
        return getConversationList(channelTypeList)
        .pipe(
            expand(res => {
                console.log('getRangeRes:');
                console.log(res);
                if (res.ok == true && res.response_metadata && res.response_metadata.next_cursor) {
                    return getConversationList(channelTypeList, res.response_metadata.next_cursor);
                } else if (res.ok == true && channelTypeList.length > 1) {
                    // 取得したいチャンネル種別リストがまだ残っている場合は先頭を削除して取得続行
                    channelTypeList.shift();
                    return getConversationList(channelTypeList);
                } else {
                    return EMPTY;
                }

            }),
            concatMap(result => result.channels ? result.channels.map(channel => channel as SlackChannel) : []),
            reduce<SlackChannel, SlackChannel[]>((acc, value) => {
                acc.push(value);
                return acc;
            }, [])
        );
    }

    sendPrivateNotification(message: string, id?: number): Observable<any> {
        const endpointUrl = environment.apiBaseUrl + '/notification';
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const data: { message: string, resource: string, user_id?: number } = {
            message,
            resource: 'slack',
        };
        if (id !== undefined) {
            data.user_id = id;
        }
        return this.http.post(endpointUrl, data, options);
    }

    sendCustomNotification(message: string, id?: number): Observable<any> {
        const endpointUrl = environment.apiBaseUrl + '/notification';
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const data: { message: string, resource: string, reception_flow_function_id?: number } = {
            message,
            resource: 'slack',
        };
        if (id !== undefined) {
            data.reception_flow_function_id = id;
        }
        return this.http.post(endpointUrl, data, options);
    }

    sendDivisionNotification(message: string, id?: number): Observable<any> {
        const endpointUrl = environment.apiBaseUrl + '/notification';
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const data: { message: string, resource: string, division_id?: number } = {
            message,
            resource: 'slack',
        };
        if (id !== undefined) {
            data.division_id = id;
        }
        return this.http.post(endpointUrl, data, options);
    }

    private getRedirectUri(state?: string): string {
        let redirectUri = environment.slackSettings.PersonalRedirectUrl;
        if (state) {
            redirectUri = `${redirectUri}/${state}`;
        }
        return redirectUri;
    }

    private handleError(result: SlackResponse) {
        if (result.error) {
            switch (result.error) {
                case 'not_authed':
                case 'invalid_auth':
                case 'account_inactive':
                case 'token_revoked':
                case 'no_permission':
                case 'missing_scope':
                    throw new NotAuthorizedError(result.error);
                default:
                    throw new Error(result.error);
            }
        }
        throw new Error('Unknown error');
    }

    /**
     * use this to make a Base64 encoded string URL friendly,
     * i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
     * characters are removed
     *
     * @param str the encoded string
     * @returns the URL friendly encoded String
     */
    private base64EncodeUrl(str) {
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
    }
}
