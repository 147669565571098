import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import QRCodeStyling from "qr-code-styling";
import { environment } from '../../../environments/environment';
import { IdentityProvider } from '../../auth/identity-provider.enum';
import { AuthProvider } from '../../auth/auth-provider.enum';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../core/user';
import { UserService, AuthType } from '../../core/user.service';

@Component({
    selector: 'app-mfa-app',
    templateUrl: './mfa-app.component.html',
    styleUrls: ['./mfa-app.component.scss']
})
export class MfaAppComponent implements OnInit {
    private user: User;
    private userSubscription: Subscription;
    private formChangedSubscription: Subscription;

    formGroup: UntypedFormGroup;

    loading = false;
    submitted = false;
    failed = false;

    code = new UntypedFormControl('', [Validators.required]);

    qrCode = null;

    @ViewChild('canvas') canvas: ElementRef;

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private userService: UserService
    ) {
        this.setup();
    }

    ngOnInit() {
        this.createFormGroup();
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.formChangedSubscription) {
            this.formChangedSubscription.unsubscribe();
        }
    }

    private setup() {
        this.loading = true;
        this.userSubscription = this.userService.current(true).subscribe(
            user => {
                this.user = user;
                this.userService.associateSoftwareToken().subscribe(
                    (secretCode) => {
                        var totpUri = "otpauth://totp/MFA:"+ this.user.email + "?secret=" + secretCode + "&issuer=Rakuneko";
                        this.createQR(totpUri);
                        this.loading = false;
                    },
                    error => {
                        console.error("associateSoftwareToken failed: " + error);
                        this.loading = false;
                    },
                );
            }
        );
    }

    private createQR(url: string) {
        if (this.canvas == undefined) {
          return
        }
  
        this.qrCode = new QRCodeStyling({
            width: 304,
            height: 304,
            data: url
        });
  
        this.qrCode.append(this.canvas.nativeElement);
        this.canvas.nativeElement.lastChild.style.width = "150px";
    }

    private createFormGroup() {
        this.formGroup = new UntypedFormGroup({
            code: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern("^[0-9]*$"),
                Validators.minLength(6)
            ])
        });
        this.formChangedSubscription = this.formGroup.valueChanges.subscribe(val => {
            this.submitted = false;
        });
    }

    public hasError(controlName: string, errorName: string): boolean {
        return this.formGroup.controls[controlName].hasError(errorName);
    }

    get f(): any { return this.formGroup.controls; }

    onSubmit() {
        this.failed = false;
        this.submitted = true;

        if (this.formGroup.invalid) {
            return;
        }
        this.loading = true;

        console.log('ok', this.f.code.value);
        this.userService.verifyAndEnableTOTP(this.f.code.value).subscribe(
            () => {
                console.log("verifyAndEnableMFA complete");
                this.user.mfa_enabled = +(!this.user.mfa_enabled);
                this.userService.update(this.user).subscribe(
                    result => {
                        console.log('mfaEnabled complete');
                        localStorage.setItem('authType', AuthType.TOTP);
                        this.router.navigate(['/account_setting/profile']);
                    },
                    error => {
                        console.log('error => ' + JSON.stringify(error));
                        this.failed = true;
                        this.loading = false;
                    }
                );
            },
            error => {
                console.error("verifyAndEnableMFA failed: " + error);
                this.loading = false;
                this.failed = true;
            },
        );
    }

    get errorClass() {
        return this.formGroup.invalid || this.failed
          ? 'visibility:visible'
          : 'visibility:hidden';
    }

}
