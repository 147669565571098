<div class="loading-shade" *ngIf="loading">
  <mat-spinner [diameter]="70" *ngIf="loading"></mat-spinner>
</div>
<div class="container">
  <div class="vertical-center">
    <div *ngIf="!enteredPassword">
      <p class="title">{{ 'mfa.password.title' | translate }}</p>
      <p class="subtitle">{{ 'mfa.password.subtitle' | translate }}</p>
  
      <form [formGroup]="formGroup" class="form" (ngSubmit)="onSubmit()">
        <input matInput
          type="password"
          formControlName="password"
          class="form-control input-password"
          placeholder="{{ 'mfa.password.placeholder' | translate }}"
          [ngClass]="{ 'is-invalid': submitted &&  (f.password.errors || failed) }"
        >
          <div class="password-error" [style]="errorClass">
            <div *ngIf="submitted && f.password.errors && f.password.errors.required">{{ 'mfa.password.required' | translate }}</div>
            <div *ngIf="submitted && f.password.errors && !f.password.errors.required">{{ 'mfa.password.passwordError' | translate }}</div>
            <div *ngIf="submitted && failed">{{ 'mfa.password.passwordError' | translate }}</div>
          </div>
        <div class="submit-area">
          <button mat-button type="submit" class="btn-password-submit">
            <p class="btn-title">{{ 'mfa.password.confirm' | translate }}</p>
          </button>
          <a class="link-cancel" href="/mfa/select">{{ 'common.cancel' | translate }}</a>
        </div>
      </form>  
    </div>
    <div *ngIf="enteredPassword && !registeredPhone">
      <p class="title">{{ 'mfa.sms.title' | translate }}</p>
      <p class="subtitle">{{ 'mfa.sms.subtitle' | translate }}</p>

      <form [formGroup]="formGroup" class="form">
        <select class="country-select form-control form-control-borderless form-control-select px-0" panelClass="testClass"
          formControlName="selectCode"
          (change)="changeSelectedCountryCode($event.target.value)">
          <option *ngFor="let code of codes; let i = index;"
            [value]="code"
            [selected]="selectCode === code">
            &nbsp;&nbsp;&nbsp;{{countries[i].name}}&nbsp;+{{code}}
          </option>
        </select>
        <input appPhoneMask formControlName="phoneNumber" class="input-phone" [countryCode]="selectedCountryCode" matInput placeholder="{{ 'mfa.sms.placeholder' | translate }}" maxlength="20"
          [ngClass]="{ 'is-invalid': formGroup.controls['phoneNumber'].hasError('phoneNumberInvalid') }">
        <div class="phone-error" [style]="errorClass">
          {{ 'mfa.sms.phoneError' | translate }}
        </div>
        <div class="submit-area">
          <button mat-button class="btn-phone-submit" [disabled]="formGroup.controls['phoneNumber'].hasError('phoneNumberInvalid')" (click)="onSubmit()">
            <p class="btn-title">{{ 'mfa.sms.add' | translate }}</p>
          </button>
          <a class="link-cancel" href="/mfa/select">{{ 'common.cancel' | translate }}</a>
        </div>
      </form>
    </div>
    <div *ngIf="enteredPassword && registeredPhone">
      <p class="title">{{ 'mfa.code.title' | translate }}</p>
      <p class="subtitle">{{ 'mfa.code.subtitle' | translate }}</p>
  
      <form [formGroup]="formGroup" class="form" (ngSubmit)="onSubmit()">
        <input matInput
          type="string"
          formControlName="code"
          class="form-control input-code"
          placeholder="{{ 'mfa.code.placeholder' | translate }}"
          maxlength="6"
          [ngClass]="{ 'is-invalid': submitted &&  (f.code.errors || failed) }"
        >
          <div class="code-error" [style]="errorClass">
            <div *ngIf="submitted && f.code.errors && f.code.errors.required">{{ 'mfa.code.required' | translate }}</div>
            <div *ngIf="submitted && f.code.errors && !f.code.errors.required">{{ 'mfa.code.inputError' | translate }}</div>
            <div *ngIf="submitted && failed">{{ 'mfa.code.mismatch' | translate }}</div>
          </div>
        <div class="submit-area">
          <button mat-button type="submit" class="btn-code-submit">
            <p class="btn-title">{{ 'mfa.code.activation' | translate }}</p>
          </button>
          <a class="link-cancel" href="/mfa/select">{{ 'common.cancel' | translate }}</a>
        </div>
      </form>  
    </div>
  </div>
</div>
