import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription, Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { environment } from '../../../environments/environment';

export interface Information {
    date: string;
    title: string;
    content: string;
    url?: string;
}

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss'],
})
export class InformationComponent implements OnInit {
    informations: Information[];

    constructor(
        private http: HttpClient,
        private readonly translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.load().subscribe(
            result => {
                this.informations = JSON.parse(JSON.stringify(result).replace(/\\\\/g, '\\'));
            }
        );
    }

    load(): Observable<Information[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };

        const informationUrl = environment.name === 'Development' ? '../../assets/json/information.json' : `${environment.siteBaseUrl}/asset/json/information.json`;
        return this.http.get(informationUrl, httpOptions).pipe(map((result: any) => {
            return result;
        }, error => { return [] }));
    }

    goToLink(url: string = null) {
        if (url) {
            window.open(url, "_blank");
        }
    }
}
