import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { PageModule } from './page/page.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { LoginModule } from './login/login.module';
import { AppRoutingModule } from './app-routing.module';
import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { MinimaLight } from '@alyle/ui/themes/minima';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NavigationService } from './core/navigation.service';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './locales/', '.json?cb=' + new Date().getTime());
}

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        AuthModule,
        LandingPageModule,
        LoginModule,
        PageModule,
        AppRoutingModule,
        LyThemeModule.setTheme('minima-light'),
        LyImageCropperModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        NgxStripeModule.forRoot(
            environment.stripeSecretKey
        ),
        GoogleTagManagerModule.forRoot({
            id: environment.gtmCode,
        }),
    ],
    providers: [
        httpInterceptorProviders,
        { provide: LY_THEME, useClass: MinimaLight, multi: true },
        NavigationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
