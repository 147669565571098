import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginCodeComponent } from './login-code/login-code.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { RestrictComponent } from './restrict/restrict.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginCodeComponent,
        children: [
            {
                path: '',
                redirectTo: 'code',
                pathMatch: 'full',
            },
            {
                path: 'code',
                component: LoginCodeComponent,
            }
        ]
    },
    {
        path: 'login',
        component: ForgotPasswordComponent,
        children: [
            {
                path: '',
                redirectTo: 'forgot',
                pathMatch: 'full'
            },
            {
                path: 'forgot',
                component: ForgotPasswordComponent,
            }
        ]
    },
    {
        path: 'login',
        component: ResetPasswordComponent,
        children: [
            {
                path: '',
                redirectTo: 'reset',
                pathMatch: 'full',
            },
            {
                path: 'reset',
                component: ResetPasswordComponent,
            }
        ]
    },
    {
        path: 'login',
        component: NewPasswordComponent,
        children: [
            {
                path: '',
                redirectTo: 'new',
                pathMatch: 'full',
            },
            {
                path: 'new',
                component: NewPasswordComponent,
            }
        ]
    },
    {
        path: 'access',
        component: RestrictComponent,
        children: [
            {
                path: '',
                redirectTo: '',
                pathMatch: 'full',
            },
            {
                path: 'restrict',
                component: RestrictComponent,
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule { }
