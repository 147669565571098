/// <reference types="chrome"/>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Token } from './token';

const ID_TOKEN = 'reception_id_token';
const ACCESS_TOKEN = 'reception_access_token';
const REFRESH_TOKEN = 'reception_refresh_token';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() { }

    setToken(token: Token) {
        this.saveToLocalStorage(
            {
                [ID_TOKEN]: token.idToken,
                [ACCESS_TOKEN]: token.accessToken,
                [REFRESH_TOKEN]: token.refreshToken,
            }
        );
    }

    getToken(): Observable<Token> {
        return this.readFromLocalStorage([ID_TOKEN, ACCESS_TOKEN, REFRESH_TOKEN]).pipe(
            map(result => {
                return {
                    idToken: (result) ? result[ID_TOKEN] : undefined,
                    accessToken: (result) ? result[ACCESS_TOKEN] : undefined,
                    refreshToken: (result) ? result[REFRESH_TOKEN] : undefined,
                }
            }),
        );
    }

    deleteToken(): void {
        this.removeFromLocalStorage([ID_TOKEN, ACCESS_TOKEN, REFRESH_TOKEN]);
    }

    private saveToLocalStorage(data: { [key: string]: string }): void {
        if (typeof(chrome) !== 'undefined' && chrome.storage !== undefined) {
            chrome.storage.local.set(data);
        } else {
            for (const key of Object.keys(data)) {
                localStorage.setItem(key, data[key]);
            }
        }
    }

    private readFromLocalStorage(keys: string[]): Observable<{ [key: string]: string }> {
        return new Observable<{ [key: string]: string }>(
            observer => {
                if (typeof(chrome) !== 'undefined' && chrome.storage !== undefined) {
                    chrome.storage.local.get(keys, (result) => observer.next(result));
                } else {
                    const result: { [key: string]: string } = {};
                    for (const key of keys) {
                        result[key] = localStorage.getItem(key);
                    }
                    observer.next(result);
                }
                observer.complete();
            },
        );
    }

    private removeFromLocalStorage(keys: string[]): void {
        if (typeof(chrome) !== 'undefined' && chrome.storage !== undefined) {
            chrome.storage.local.remove(keys);
        } else {
            for (const key of keys) {
                localStorage.removeItem(key);
            }
        }
    }
}
