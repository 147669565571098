import { Component, OnInit, Inject, } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    trialEnd: string;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'free-trial-info',
  templateUrl: './free-trial-info-dialog.component.html',
  styleUrls: ['./free-trial-info-dialog.component.scss'],
})
export class FreeTrialInfoDialogComponent implements OnInit {

    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<FreeTrialInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
    }

    goToPlan() {
        this.close();
        this.router.navigate(['plan', {}]);
    }

    close(): void {
        this.dialogRef.close();
    }
}
