import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-request-additional-options-dialog',
  templateUrl: './request-additional-options-dialog.component.html',
  styleUrls: ['./request-additional-options-dialog.component.scss']
})
export class RequestAdditionalOptionsDialogComponent implements OnInit {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<RequestAdditionalOptionsDialogComponent>,
  ) { }

  ngOnInit(): void {
  }


  goToPlan() {
    this.close();
    this.router.navigate(['plan', {}]);
  }

  close(): void {
      this.dialogRef.close();
  }
}
