import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

@NgModule({
    declarations: [
        AuthCallbackComponent
    ],
    imports: [
        CommonModule,
        AuthRoutingModule
    ],
    providers: [],
})
export class AuthModule { }
