import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Information } from './information';

@Injectable({
    providedIn: 'root'
})
export class InformationService {

    private readonly endpointUrl = environment.apiBaseUrl + '/information';

    constructor(
        private http: HttpClient,
    ) { }

    public informationList(isPublish: boolean, publishedAt: Date): Observable<Information[]> {
        return this.http.get(this.endpointUrl, {
            params: {
                is_publish: isPublish ? '1' : '0',
                published_at: publishedAt.toISOString(),
            }
        }).pipe(
            map((result) => {
                return result as Information[];
            })
        );
    }

    public create({name, linkUrl, isPublish, publishedAt}) {
        return this.http.post(
            this.endpointUrl,
            {
                name,
                link_url: linkUrl,
                is_publish: isPublish,
                published_at: `${publishedAt.format('YYYY/MM/DD')} 00:00:00`,
            },
            {
                headers: { 'Content-Type': 'application/json'}
            }
        );
    }

    public update(information: Information, {name, linkUrl, isPublish, publishedAt}): Observable<boolean> {
        const endpointUrl = `${this.endpointUrl}/${information.id}`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const data = {
            name,
            link_url: linkUrl,
            is_publish: isPublish ? 1 : 0,
            published_at: `${publishedAt.format('YYYY/MM/DD')} 00:00:00`,
        };

        return this.http.put(endpointUrl, data, options).pipe(
            map(
                (result) => !!result
            )
        );
    }
}
