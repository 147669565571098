import {Component, OnDestroy, OnInit} from '@angular/core';
import { InformationService } from '../information.service';
import { PlanService } from '../../core/credit.service';
import { Information } from '../information';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RequestAdditionalOptionsDialogComponent } from './request-additional-options-dialog.component/request-additional-options-dialog.component';
import { FreeTrialInfoDialogComponent } from './free-trial-info-dialog.component/free-trial-info-dialog.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    informationList: Information[] = [];
    isLoadingResults = true;

    private informationSubscription: Subscription;

    constructor(
        private informationService: InformationService,
        private dialog: MatDialog,
        private planService: PlanService,
    ) { }

    ngOnInit() {
        console.log("DashboardComponent");
        // this.search();
        this.planService.contractGet().subscribe(entities => {
            if (entities && entities.payment_method === 'credit_card' && !entities.register_credit_card) {
                // クレジットカード未登録の場合ダイアログ表示
                this.dialog.open(FreeTrialInfoDialogComponent, {
                    width: '700px',
                    disableClose: true,
                    data: { trialEnd: new Date(Date.parse(entities.trial_end)).toLocaleDateString() }
                });
            } else if (entities && entities.register_credit_card && this.isIncompleteContract(entities)) {
                // オプション追加ダイアログ表示
                this.dialog.open(RequestAdditionalOptionsDialogComponent, {
                    width: '700px',
                    disableClose: true
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.informationSubscription) {
            this.informationSubscription.unsubscribe();
        }
    }

    private search() {
        console.log("search");
        /** Based on the screen size, switch from standard to one column per row */
        this.isLoadingResults = true;
        this.informationSubscription = this.informationService.informationList(true, new Date()).subscribe(
            result => {
                this.informationList = result;
            },
            error => console.error(error),
            () => {
                 this.isLoadingResults = false;
            });
    }

    private isIncompleteContract(entities) {
        // 契約内容が、ご利用人数50名でオプション無しの場合
        return entities.rakuneko.users === 50 && !Object.values(entities.rakuneko).includes(true);
    }
}
