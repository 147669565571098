<mat-toolbar class="landing-header mat-elevation-z3">
  <img height="45" src="../../assets/img/RCP_logo.png"
    srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
  <span class="toolbar-spacer"></span>
  <!-- <button mat-raised-button color="primary" routerLink="/login">ログイン</button> -->
</mat-toolbar>

<div class="landing-content">
  <div class="content-center-container">
    <img height="200" src="../../assets/img/RCP_logo.png"
      srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
    <h1>受付を楽しく、簡単に</h1>
    <img height="500" src="../../assets/img/ipad-menu.png"
      srcset="../../assets/img/ipad-menu.png 1x, ../../assets/img/ipad-menu.png 2x">
    <div class="login-button-container">
      <!-- <button mat-raised-button color="primary" routerLink="/login">ログイン</button> -->
    </div>
  </div>
  <div>
    <div class="description-container">
      <h3>Googleカレンダー連携</h3>
      Googleカレンダーと連携をすることにより、ご自身のカレンダー予定と
      本アプリが連携をし、アポイントメントの登録が可能となります。<br>
      本アプリはカレンダーを読取り専用のAPIにて接続するため、
      ユーザー様のカレンダーを改変することはございません。
    </div>
    <div class="description-container">
      <h3>Googleカレンダーとの連携の手順</h3>
      １．お使いの G Suite アカウントより本システムへログインをします<br>
      <img height="150" src="../../assets/img/guide/1.png"><br>
      ２．アカウント設定 > カレンダー連携タブを開きます<br>
      <img height="150" src="../../assets/img/guide/2.png"><br>
      ３．Sign in with Google ボタンをクリックします<br>
      <img height="150" src="../../assets/img/guide/3.png"><br>
      ４．お使いの G Suite アカウントを選択します<br>
      <img height="350" src="../../assets/img/guide/4.png"><br>
      ５．許可をクリックします<br>
      <img height="150" src="../../assets/img/guide/5_1.png"><br>
      <img height="350" src="../../assets/img/guide/5_2.png"><br>
      ６．認証済み となると連携が完了しております<br>
      <img height="100" src="../../assets/img/guide/6.png"><br>
    </div>
  </div>
</div>

<footer class=landing-footer>
  <div class=landing-footer-container>
    <div>Copyright ©<a href="https://www.protosolution.co.jp/">PROTO SOLUTION</a>, All rights reserved.</div>
    <div>
      <ul>
        <li><a href="https://reception.ai-force.ai/asset/document/privacy_policy.html">プライバシーポリシー</a></li>
        <li><a href="https://www.protosolution.co.jp/">運営会社</a></li>
      </ul>
    </div>
  </div>
</footer>