import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf, iif, Observer, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { creditManagement } from './credit-management';
import { organizationContractManagement } from './organization-contract-management';
import { RakunekoPlanData } from './rakuneko-plan-data';
import { environment } from '../../environments/environment';

interface CreditResult {
    billingPortalUrl: string;
    products: creditManagement[];
}

@Injectable({
    providedIn: 'root'
})
export class PlanService {

    private creditManagement: creditManagement;
    private creditManagements: creditManagement[];
    private organizationContractManagement: organizationContractManagement;
    private readonly endpointUrl = environment.apiBaseUrl + '/plan';
    private readonly organizationContractUrl = environment.apiBaseUrl + '/organization/contract';

    private contractDataSubject = new BehaviorSubject<organizationContractManagement>(null);
    public contractData$ = this.contractDataSubject.asObservable();

    constructor(
        private http: HttpClient,
    ) {
    }

    setContractData(data: organizationContractManagement) {
        if (data.trial_end !== null && Date.now() < Date.parse(data.trial_end)) {
            data = { ...data, ...{
                rakuneko: {
                    users: 9999,
                    phone_sms: true,
                    interphone_mobile: true,
                    interphone_outsideline: true,
                    room: true,
                    qr: true,
                    extension_phone: true,
                    akerun: true,
                    event: true,
                    handwritten: true
                },
                rakuneko_room_users: {
                    users: 9999,
                    rakuneko: true
                },
                rakuneko_room_rooms: {
                    rooms: 9999
                },
                rakuneko_roomplus_users: {
                    users: 9999
                },
                rakuneko_roomplus_Rooms: {
                    rooms: 9999
                },
                is_trial: true
            }};
        } else {
            data.is_trial = false;
        }
        this.contractDataSubject.next(data);
    }

    public creditGet(forceLoad: boolean = false): Observable<creditManagement[]> {
        return (!this.creditManagements || forceLoad) ? this.http.get(this.endpointUrl).pipe(map(result => {
            const r = result as CreditResult;
            console.log(r.products);
            return this.creditManagements = r.products;
        })) : observableOf(this.creditManagements);
    }

    public contractGet(forceLoad: boolean = false): Observable<organizationContractManagement> {
        return (!this.organizationContractManagement || forceLoad) ? this.http.get(this.organizationContractUrl).pipe(map(result => {
            console.log(result);
            this.organizationContractManagement = result as organizationContractManagement;
            return this.organizationContractManagement;
        })) : observableOf(this.organizationContractManagement);
    }

    public delete() {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        this.http.get(this.endpointUrl, options).pipe(
            map(
                (result) => !!result
            )
        )
    }
}
