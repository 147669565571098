<div id="fill">
  <div id="container" class="artboard scene">
    <div id="primary">
      <div id="primary_outer"></div>
      <div id="primary_inner"></div>
    </div>

    <div id="logo">
      <img src="../../assets/img/RCP_logo.png"
           srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
    </div>

    <div id="label_login_code">2段階認証コードを入力してください。</div>
    <div id="label_code_send">認証アプリに表示されている認証コードを入力してください。</div>

    <form class="form" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <div id="input_area" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
        <div class="form-group">
          <input matInput type="email" id="email" formControlName="email" class="form-control" placeholder="6桁の認証コード" required />
        </div>
      </div>
      <div id="input_error_code" *ngIf="submitted" class="invalid-feedback">
        <div *ngIf="f.code.errors && f.code.errors.code">認証コードが正しくありません</div>
        <div *ngIf="f.code.errors && f.code.errors.required">認証コードを入力してください</div>
      </div>
      <div id="not_found_error" *ngIf="submitted && userNotFound">ユーザーが見つからないため、パスワードリセットできません。<br />メールアドレス確認後、時間をおいて再試行してください。</div>

      <div id="secondary_section">
        <div id="secondary_button_section">
          <button
            mat-button
            mdbBtn
            mdbWavesEffect
            type="submit"
            color="success"
            id="button_input_reset"
            class="button-element mat-button"
            [class.spinner]="loading"
            [disabled]="loading"
            (click)="onSubmit()"
          >
            <span class="spinner-border spinner-border-sm mr-1"></span>
          </button>
          <div id="label_input_reset">送信する</div>
        </div>
      </div>
    </form>
    <div id="label_back_login">
      <a href="login">＜ログインへ戻る</a>
    </div>
  </div>
</div>
