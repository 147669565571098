import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf, iif, Observer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { changePaymentMethodManagement } from './change-payment-method-management';
import { environment } from '../../environments/environment';

interface ChangePaymentMethodResult {
    contract: changePaymentMethodManagement[];
}

@Injectable({
    providedIn: 'root'
})
export class ChangePaymentMethodService {

    private changePaymentMethodManagement: changePaymentMethodManagement;
    private changePaymentMethodManagements: changePaymentMethodManagement[];
    private readonly endpointUrl = environment.apiBaseUrl + '/change-payment-method';

    constructor(
        private http: HttpClient,
    ) {
    }

    public changePaymentMethodGet(forceLoad: boolean = false): Observable<changePaymentMethodManagement[]> {
        return (!this.changePaymentMethodManagement || forceLoad) ? this.http.get(this.endpointUrl).pipe(map(result => {
            const r = result as ChangePaymentMethodResult;
            return this.changePaymentMethodManagements = r.contract;
        })) : observableOf(this.changePaymentMethodManagements);
    }
}
