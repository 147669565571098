import { Country } from './country.model';

export var countries: Country[] = [
    { name: "Afghanistan", code2: "AF", code3: "AFG", code: "93" },
    { name: "Albania", code2: "AL", code3: "ALB", code: "355" },
    { name: "Algeria", code2: "DZ", code3: "DZA", code: "213" },
    { name: "American Samoa", code2: "AS", code3: "ASM", code: "1-684" },
    { name: "Andorra", code2: "AD", code3: "AND", code: "376" },
    { name: "Angola", code2: "AO", code3: "AGO", code: "244" },
    { name: "Anguilla", code2: "AI", code3: "AIA", code: "1-264" },
    { name: "Antarctica", code2: "AQ", code3: "ATA", code: "672" },
    { name: "Antigua and Barbuda", code2: "AG", code3: "ATG", code: "1-268" },
    { name: "Argentina", code2: "AR", code3: "ARG", code: "54" },
    { name: "Armenia", code2: "AM", code3: "ARM", code: "374" },
    { name: "Aruba", code2: "AW", code3: "ABW", code: "297" },
    { name: "Australia", code2: "AU", code3: "AUS", code: "61" },
    { name: "Austria", code2: "AT", code3: "AUT", code: "43" },
    { name: "Azerbaijan", code2: "AZ", code3: "AZE", code: "994" },
    { name: "Bahamas", code2: "BS", code3: "BHS", code: "1-242" },
    { name: "Bahrain", code2: "BH", code3: "BHR", code: "973" },
    { name: "Bangladesh", code2: "BD", code3: "BGD", code: "880" },
    { name: "Barbados", code2: "BB", code3: "BRB", code: "1-246" },
    { name: "Belarus", code2: "BY", code3: "BLR", code: "375" },
    { name: "Belgium", code2: "BE", code3: "BEL", code: "32" },
    { name: "Belize", code2: "BZ", code3: "BLZ", code: "501" },
    { name: "Benin", code2: "BJ", code3: "BEN", code: "229" },
    { name: "Bermuda", code2: "BM", code3: "BMU", code: "1-441" },
    { name: "Bhutan", code2: "BT", code3: "BTN", code: "975" },
    { name: "Bolivia", code2: "BO", code3: "BOL", code: "591" },
    { name: "Bosnia and Herzegovina", code2: "BA", code3: "BIH", code: "387" },
    { name: "Botswana", code2: "BW", code3: "BWA", code: "267" },
    { name: "Brazil", code2: "BR", code3: "BRA", code: "55" },
    { name: "British Indian Ocean Territory", code2: "IO", code3: "IOT", code: "246" },
    { name: "British Virgin Islands", code2: "VG", code3: "VGB", code: "1-284" },
    { name: "Brunei", code2: "BN", code3: "BRN", code: "673" },
    { name: "Bulgaria", code2: "BG", code3: "BGR", code: "359" },
    { name: "Burkina Faso", code2: "BF", code3: "BFA", code: "226" },
    { name: "Burundi", code2: "BI", code3: "BDI", code: "257" },
    { name: "Cambodia", code2: "KH", code3: "KHM", code: "855" },
    { name: "Cameroon", code2: "CM", code3: "CMR", code: "237" },
    { name: "Canada", code2: "CA", code3: "CAN", code: "1" },
    { name: "Cape Verde", code2: "CV", code3: "CPV", code: "238" },
    { name: "Cayman Islands", code2: "KY", code3: "CYM", code: "1-345" },
    { name: "Central African Republic", code2: "CF", code3: "CAF", code: "236" },
    { name: "Chad", code2: "TD", code3: "TCD", code: "235" },
    { name: "Chile", code2: "CL", code3: "CHL", code: "56" },
    { name: "China", code2: "CN", code3: "CHN", code: "86" },
    { name: "Christmas Island", code2: "CX", code3: "CXR", code: "61" },
    { name: "Cocos Islands", code2: "CC", code3: "CCK", code: "61" },
    { name: "Colombia", code2: "CO", code3: "COL", code: "57" },
    { name: "Comoros", code2: "KM", code3: "COM", code: "269" },
    { name: "Cook Islands", code2: "CK", code3: "COK", code: "682" },
    { name: "Costa Rica", code2: "CR", code3: "CRI", code: "506" },
    { name: "Croatia", code2: "HR", code3: "HRV", code: "385" },
    { name: "Cuba", code2: "CU", code3: "CUB", code: "53" },
    { name: "Curacao", code2: "CW", code3: "CUW", code: "599" },
    { name: "Cyprus", code2: "CY", code3: "CYP", code: "357" },
    { name: "Czech Republic", code2: "CZ", code3: "CZE", code: "420" },
    { name: "Democratic Republic of the Congo", code2: "CD", code3: "COD", code: "243" },
    { name: "Denmark", code2: "DK", code3: "DNK", code: "45" },
    { name: "Djibouti", code2: "DJ", code3: "DJI", code: "253" },
    { name: "Dominica", code2: "DM", code3: "DMA", code: "1-767" },
    { name: "Dominican Republic", code2: "DO", code3: "DOM", code: "1-809, 1-829, 1-849" },
    { name: "East Timor", code2: "TL", code3: "TLS", code: "670" },
    { name: "Ecuador", code2: "EC", code3: "ECU", code: "593" },
    { name: "Egypt", code2: "EG", code3: "EGY", code: "20" },
    { name: "El Salvador", code2: "SV", code3: "SLV", code: "503" },
    { name: "Equatorial Guinea", code2: "GQ", code3: "GNQ", code: "240" },
    { name: "Eritrea", code2: "ER", code3: "ERI", code: "291" },
    { name: "Estonia", code2: "EE", code3: "EST", code: "372" },
    { name: "Ethiopia", code2: "ET", code3: "ETH", code: "251" },
    { name: "Falkland Islands", code2: "FK", code3: "FLK", code: "500" },
    { name: "Faroe Islands", code2: "FO", code3: "FRO", code: "298" },
    { name: "Fiji", code2: "FJ", code3: "FJI", code: "679" },
    { name: "Finland", code2: "FI", code3: "FIN", code: "358" },
    { name: "France", code2: "FR", code3: "FRA", code: "33" },
    { name: "French Polynesia", code2: "PF", code3: "PYF", code: "689" },
    { name: "Gabon", code2: "GA", code3: "GAB", code: "241" },
    { name: "Gambia", code2: "GM", code3: "GMB", code: "220" },
    { name: "Georgia", code2: "GE", code3: "GEO", code: "995" },
    { name: "Germany", code2: "DE", code3: "DEU", code: "49" },
    { name: "Ghana", code2: "GH", code3: "GHA", code: "233" },
    { name: "Gibraltar", code2: "GI", code3: "GIB", code: "350" },
    { name: "Greece", code2: "GR", code3: "GRC", code: "30" },
    { name: "Greenland", code2: "GL", code3: "GRL", code: "299" },
    { name: "Grenada", code2: "GD", code3: "GRD", code: "1-473" },
    { name: "Guam", code2: "GU", code3: "GUM", code: "1-671" },
    { name: "Guatemala", code2: "GT", code3: "GTM", code: "502" },
    { name: "Guernsey", code2: "GG", code3: "GGY", code: "44-1481" },
    { name: "Guinea", code2: "GN", code3: "GIN", code: "224" },
    { name: "Guinea-Bissau", code2: "GW", code3: "GNB", code: "245" },
    { name: "Guyana", code2: "GY", code3: "GUY", code: "592" },
    { name: "Haiti", code2: "HT", code3: "HTI", code: "509" },
    { name: "Honduras", code2: "HN", code3: "HND", code: "504" },
    { name: "Hong Kong", code2: "HK", code3: "HKG", code: "852" },
    { name: "Hungary", code2: "HU", code3: "HUN", code: "36" },
    { name: "Iceland", code2: "IS", code3: "ISL", code: "354" },
    { name: "India", code2: "IN", code3: "IND", code: "91" },
    { name: "Indonesia", code2: "ID", code3: "IDN", code: "62" },
    { name: "Iran", code2: "IR", code3: "IRN", code: "98" },
    { name: "Iraq", code2: "IQ", code3: "IRQ", code: "964" },
    { name: "Ireland", code2: "IE", code3: "IRL", code: "353" },
    { name: "Isle of Man", code2: "IM", code3: "IMN", code: "44-1624" },
    { name: "Israel", code2: "IL", code3: "ISR", code: "972" },
    { name: "Italy", code2: "IT", code3: "ITA", code: "39" },
    { name: "Ivory Coast", code2: "CI", code3: "CIV", code: "225" },
    { name: "Jamaica", code2: "JM", code3: "JAM", code: "1-876" },
    { name: "日本", code2: "JP", code3: "JPN", code: "81" },
    { name: "Jersey", code2: "JE", code3: "JEY", code: "44-1534" },
    { name: "Jordan", code2: "JO", code3: "JOR", code: "962" },
    { name: "Kazakhstan", code2: "KZ", code3: "KAZ", code: "7" },
    { name: "Kenya", code2: "KE", code3: "KEN", code: "254" },
    { name: "Kiribati", code2: "KI", code3: "KIR", code: "686" },
    { name: "Kosovo", code2: "XK", code3: "XKX", code: "383" },
    { name: "Kuwait", code2: "KW", code3: "KWT", code: "965" },
    { name: "Kyrgyzstan", code2: "KG", code3: "KGZ", code: "996" },
    { name: "Laos", code2: "LA", code3: "LAO", code: "856" },
    { name: "Latvia", code2: "LV", code3: "LVA", code: "371" },
    { name: "Lebanon", code2: "LB", code3: "LBN", code: "961" },
    { name: "Lesotho", code2: "LS", code3: "LSO", code: "266" },
    { name: "Liberia", code2: "LR", code3: "LBR", code: "231" },
    { name: "Libya", code2: "LY", code3: "LBY", code: "218" },
    { name: "Liechtenstein", code2: "LI", code3: "LIE", code: "423" },
    { name: "Lithuania", code2: "LT", code3: "LTU", code: "370" },
    { name: "Luxembourg", code2: "LU", code3: "LUX", code: "352" },
    { name: "Macau", code2: "MO", code3: "MAC", code: "853" },
    { name: "Macedonia", code2: "MK", code3: "MKD", code: "389" },
    { name: "Madagascar", code2: "MG", code3: "MDG", code: "261" },
    { name: "Malawi", code2: "MW", code3: "MWI", code: "265" },
    { name: "Malaysia", code2: "MY", code3: "MYS", code: "60" },
    { name: "Maldives", code2: "MV", code3: "MDV", code: "960" },
    { name: "Mali", code2: "ML", code3: "MLI", code: "223" },
    { name: "Malta", code2: "MT", code3: "MLT", code: "356" },
    { name: "Marshall Islands", code2: "MH", code3: "MHL", code: "692" },
    { name: "Mauritania", code2: "MR", code3: "MRT", code: "222" },
    { name: "Mauritius", code2: "MU", code3: "MUS", code: "230" },
    { name: "Mayotte", code2: "YT", code3: "MYT", code: "262" },
    { name: "Mexico", code2: "MX", code3: "MEX", code: "52" },
    { name: "Micronesia", code2: "FM", code3: "FSM", code: "691" },
    { name: "Moldova", code2: "MD", code3: "MDA", code: "373" },
    { name: "Monaco", code2: "MC", code3: "MCO", code: "377" },
    { name: "Mongolia", code2: "MN", code3: "MNG", code: "976" },
    { name: "Montenegro", code2: "ME", code3: "MNE", code: "382" },
    { name: "Montserrat", code2: "MS", code3: "MSR", code: "1-664" },
    { name: "Morocco", code2: "MA", code3: "MAR", code: "212" },
    { name: "Mozambique", code2: "MZ", code3: "MOZ", code: "258" },
    { name: "Myanmar", code2: "MM", code3: "MMR", code: "95" },
    { name: "Namibia", code2: "NA", code3: "NAM", code: "264" },
    { name: "Nauru", code2: "NR", code3: "NRU", code: "674" },
    { name: "Nepal", code2: "NP", code3: "NPL", code: "977" },
    { name: "Netherlands", code2: "NL", code3: "NLD", code: "31" },
    { name: "Netherlands Antilles", code2: "AN", code3: "ANT", code: "599" },
    { name: "New Caledonia", code2: "NC", code3: "NCL", code: "687" },
    { name: "New Zealand", code2: "NZ", code3: "NZL", code: "64" },
    { name: "Nicaragua", code2: "NI", code3: "NIC", code: "505" },
    { name: "Niger", code2: "NE", code3: "NER", code: "227" },
    { name: "Nigeria", code2: "NG", code3: "NGA", code: "234" },
    { name: "Niue", code2: "NU", code3: "NIU", code: "683" },
    { name: "North Korea", code2: "KP", code3: "PRK", code: "850" },
    { name: "Northern Mariana Islands", code2: "MP", code3: "MNP", code: "1-670" },
    { name: "Norway", code2: "NO", code3: "NOR", code: "47" },
    { name: "Oman", code2: "OM", code3: "OMN", code: "968" },
    { name: "Pakistan", code2: "PK", code3: "PAK", code: "92" },
    { name: "Palau", code2: "PW", code3: "PLW", code: "680" },
    { name: "Palestine", code2: "PS", code3: "PSE", code: "970" },
    { name: "Panama", code2: "PA", code3: "PAN", code: "507" },
    { name: "Papua New Guinea", code2: "PG", code3: "PNG", code: "675" },
    { name: "Paraguay", code2: "PY", code3: "PRY", code: "595" },
    { name: "Peru", code2: "PE", code3: "PER", code: "51" },
    { name: "Philippines", code2: "PH", code3: "PHL", code: "63" },
    { name: "Pitcairn", code2: "PN", code3: "PCN", code: "64" },
    { name: "Poland", code2: "PL", code3: "POL", code: "48" },
    { name: "Portugal", code2: "PT", code3: "PRT", code: "351" },
    { name: "Puerto Rico", code2: "PR", code3: "PRI", code: "1-787, 1-939" },
    { name: "Qatar", code2: "QA", code3: "QAT", code: "974" },
    { name: "Republic of the Congo", code2: "CG", code3: "COG", code: "242" },
    { name: "Reunion", code2: "RE", code3: "REU", code: "262" },
    { name: "Romania", code2: "RO", code3: "ROU", code: "40" },
    { name: "Russia", code2: "RU", code3: "RUS", code: "7" },
    { name: "Rwanda", code2: "RW", code3: "RWA", code: "250" },
    { name: "Saint Barthelemy", code2: "BL", code3: "BLM", code: "590" },
    { name: "Saint Helena", code2: "SH", code3: "SHN", code: "290" },
    { name: "Saint Kitts and Nevis", code2: "KN", code3: "KNA", code: "1-869" },
    { name: "Saint Lucia", code2: "LC", code3: "LCA", code: "1-758" },
    { name: "Saint Martin", code2: "MF", code3: "MAF", code: "590" },
    { name: "Saint Pierre and Miquelon", code2: "PM", code3: "SPM", code: "508" },
    { name: "Saint Vincent and the Grenadines", code2: "VC", code3: "VCT", code: "1-784" },
    { name: "Samoa", code2: "WS", code3: "WSM", code: "685" },
    { name: "San Marino", code2: "SM", code3: "SMR", code: "378" },
    { name: "Sao Tome and Principe", code2: "ST", code3: "STP", code: "239" },
    { name: "Saudi Arabia", code2: "SA", code3: "SAU", code: "966" },
    { name: "Senegal", code2: "SN", code3: "SEN", code: "221" },
    { name: "Serbia", code2: "RS", code3: "SRB", code: "381" },
    { name: "Seychelles", code2: "SC", code3: "SYC", code: "248" },
    { name: "Sierra Leone", code2: "SL", code3: "SLE", code: "232" },
    { name: "Singapore", code2: "SG", code3: "SGP", code: "65" },
    { name: "Sint Maarten", code2: "SX", code3: "SXM", code: "1-721" },
    { name: "Slovakia", code2: "SK", code3: "SVK", code: "421" },
    { name: "Slovenia", code2: "SI", code3: "SVN", code: "386" },
    { name: "Solomon Islands", code2: "SB", code3: "SLB", code: "677" },
    { name: "Somalia", code2: "SO", code3: "SOM", code: "252" },
    { name: "South Africa", code2: "ZA", code3: "ZAF", code: "27" },
    { name: "South Korea", code2: "KR", code3: "KOR", code: "82" },
    { name: "South Sudan", code2: "SS", code3: "SSD", code: "211" },
    { name: "Spain", code2: "ES", code3: "ESP", code: "34" },
    { name: "Sri Lanka", code2: "LK", code3: "LKA", code: "94" },
    { name: "Sudan", code2: "SD", code3: "SDN", code: "249" },
    { name: "Suriname", code2: "SR", code3: "SUR", code: "597" },
    { name: "Svalbard and Jan Mayen", code2: "SJ", code3: "SJM", code: "47" },
    { name: "Swaziland", code2: "SZ", code3: "SWZ", code: "268" },
    { name: "Sweden", code2: "SE", code3: "SWE", code: "46" },
    { name: "Switzerland", code2: "CH", code3: "CHE", code: "41" },
    { name: "Syria", code2: "SY", code3: "SYR", code: "963" },
    { name: "Taiwan", code2: "TW", code3: "TWN", code: "886" },
    { name: "Tajikistan", code2: "TJ", code3: "TJK", code: "992" },
    { name: "Tanzania", code2: "TZ", code3: "TZA", code: "255" },
    { name: "Thailand", code2: "TH", code3: "THA", code: "66" },
    { name: "Togo", code2: "TG", code3: "TGO", code: "228" },
    { name: "Tokelau", code2: "TK", code3: "TKL", code: "690" },
    { name: "Tonga", code2: "TO", code3: "TON", code: "676" },
    { name: "Trinidad and Tobago", code2: "TT", code3: "TTO", code: "1-868" },
    { name: "Tunisia", code2: "TN", code3: "TUN", code: "216" },
    { name: "Turkey", code2: "TR", code3: "TUR", code: "90" },
    { name: "Turkmenistan", code2: "TM", code3: "TKM", code: "993" },
    { name: "Turks and Caicos Islands", code2: "TC", code3: "TCA", code: "1-649" },
    { name: "Tuvalu", code2: "TV", code3: "TUV", code: "688" },
    { name: "U.S. Virgin Islands", code2: "VI", code3: "VIR", code: "1-340" },
    { name: "Uganda", code2: "UG", code3: "UGA", code: "256" },
    { name: "Ukraine", code2: "UA", code3: "UKR", code: "380" },
    { name: "United Arab Emirates", code2: "AE", code3: "ARE", code: "971" },
    { name: "United Kingdom", code2: "GB", code3: "GBR", code: "44" },
    { name: "United States", code2: "US", code3: "USA", code: "1" },
    { name: "Uruguay", code2: "UY", code3: "URY", code: "598" },
    { name: "Uzbekistan", code2: "UZ", code3: "UZB", code: "998" },
    { name: "Vanuatu", code2: "VU", code3: "VUT", code: "678" },
    { name: "Vatican", code2: "VA", code3: "VAT", code: "379" },
    { name: "Venezuela", code2: "VE", code3: "VEN", code: "58" },
    { name: "Vietnam", code2: "VN", code3: "VNM", code: "84" },
    { name: "Wallis and Futuna", code2: "WF", code3: "WLF", code: "681" },
    { name: "Western Sahara", code2: "EH", code3: "ESH", code: "212" },
    { name: "Yemen", code2: "YE", code3: "YEM", code: "967" },
    { name: "Zambia", code2: "ZM", code3: "ZMB", code: "260" },
    { name: "Zimbabwe", code2: "ZW", code3: "ZWE", code: "263"}
];