<mat-sidenav-container class="sidenav-container">
  <!-- *ngIf="dynamicWidth" [style.width.px]="dynamicWidth" -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar>
      <img src="../../assets/img/RCP_logo.png"
        srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
    </mat-toolbar>
    <mat-nav-list>
      <!-- <a mat-list-item [routerLink]="['/']">Top</a> -->
      <!--<a mat-list-item [routerLink]="['/dashboard']">
        <img height="30" src="../../assets/img/information_icon@2x.png"
             srcset="../../assets/img/information_icon@2x.png 1x, ../../assets/img/information_icon@2x.png 2x">
        ダッシュボード
      </a> -->
      <a mat-list-item [routerLink]="['/dashboard']" (click)="checkRestriction()" routerLinkActive="active-list-item">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
            <path d="M0 0h24v24H0z" stroke="none" fill="none"/><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
          </svg>
        </div>
        <div class="list-title">{{ 'sidebar.member.home' | translate }}</div>
      </a>
      <a mat-list-item href="https://raku-neko.jp/manual/" target="_blank">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
            <path d="M 9 10 L 10 10 C 10.552 10 11 9.552 11 9 C 11 8.448 10.552 8 10 8 L 9 8 C 8.448 8 8 8.448 8 9 C 8 9.552 8.448 10 9 10 Z M 9 12 C 8.448 12 8 12.448 8 13 C 8 13.552 8.448 14 9 14 L 15 14 C 15.552 14 16 13.552 16 13 C 16 12.448 15.552 12 15 12 L 9 12 Z M 20 8.940 C 19.990 8.848 19.969 8.758 19.940 8.670 L 19.940 8.580 C 19.892 8.477 19.828 8.383 19.750 8.300 L 19.750 8.300 L 13.750 2.300 L 13.750 2.300 C 13.667 2.222 13.573 2.158 13.470 2.110 C 13.440 2.106 13.410 2.106 13.380 2.110 C 13.278 2.052 13.166 2.014 13.050 2 L 7 2 C 5.343 2 4 3.343 4.000 5.000 L 4 19 C 4.000 20.657 5.343 22 7 22 L 17 22 C 18.657 22 20 20.657 20 19 L 20 9 C 20 9 20 9 20 8.940 Z M 14 5.410 L 16.590 8 L 15 8 C 14.448 8 14 7.552 14 7 L 14 5.410 Z M 18 19 C 18 19.552 17.552 20 17 20 L 7 20 C 6.448 20 6 19.552 6 19 L 6 5 C 6 4.448 6.448 4 7 4 L 12 4 L 12 7 C 12 8.657 13.343 10 15.000 10 L 18 10 L 18 19 Z M 15 16 L 9 16 C 8.448 16 8 16.448 8 17 C 8 17.552 8.448 18 9 18 L 15 18 C 15.552 18 16 17.552 16 17 C 16 16.448 15.552 16 15 16 Z"
                  stroke-width=".1" />
          </svg>
        </div>
        <div class="list-title">{{ 'sidebar.manual' | translate }}</div>
      </a>
      <a mat-list-item [routerLink]="['/account_setting']" [disabled]="organization?.is_holdings_role === 1" (click)="checkRestriction()" routerLinkActive="active-list-item">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
            <path d="M 12 2 C 8.037 2.008 4.453 4.355 2.861 7.984 C 1.269 11.613 1.971 15.840 4.650 18.760 L 4.650 18.760 C 6.543 20.812 9.208 21.979 12.000 21.979 C 14.792 21.979 17.457 20.812 19.350 18.760 L 19.350 18.760 C 22.029 15.840 22.731 11.613 21.139 7.984 C 19.547 4.355 15.963 2.008 12.000 2.000 Z M 12 20 C 9.928 19.997 7.939 19.190 6.450 17.750 C 7.375 15.499 9.567 14.030 12 14.030 C 14.433 14.030 16.625 15.499 17.550 17.750 C 16.061 19.190 14.072 19.997 12 20 Z M 10 10 C 10 8.895 10.895 8 12 8 C 13.105 8 14 8.895 14 10 C 14 11.105 13.105 12 12 12 C 10.895 12 10 11.105 10 10 Z M 18.910 16 C 18.017 14.472 16.641 13.283 15.000 12.620 C 16.418 11.012 16.316 8.572 14.769 7.087 C 13.221 5.603 10.779 5.603 9.231 7.087 C 7.684 8.572 7.582 11.012 9 12.620 C 7.359 13.283 5.983 14.472 5.090 16 C 4.378 14.787 4.002 13.406 4.000 12.000 C 4 7.582 7.582 4 12.000 4.000 C 16.418 4.000 20 7.582 20 12.000 C 19.998 13.406 19.622 14.787 18.910 16 Z"
                  stroke-width=".1" />
          </svg>
        </div>
        <div class="list-title">{{ 'sidebar.member.accountSettings' | translate }}</div>
      </a>
      <a mat-list-item [routerLink]="['/appointment']" (click)="checkRestriction()" routerLinkActive="active-list-item">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
            <path d="M 17 11 L 18 11 C 18.552 11 19 10.552 19 10 L 19 9 C 19 8.448 18.552 8 18 8 L 17 8 C 16.448 8 16 8.448 16 9 L 16 10 C 16 10.552 16.448 11 17 11 Z M 6 12 L 11 12 C 11.552 12 12 11.552 12 11 C 12 10.448 11.552 10 11 10 L 6 10 C 5.448 10 5 10.448 5 11 C 5 11.552 5.448 12 6 12 Z M 22 4 L 2 4 C 1.448 4 1.000 4.448 1 5 L 1 19 C 1 19.552 1.448 20 2.000 20 L 22 20 C 22.552 20 23 19.552 23 19 L 23 5 C 23 4.448 22.552 4 22 4 Z M 21 18 L 3 18 L 3 6 L 21 6 L 21 18 Z M 6 16 L 11 16 C 11.552 16 12 15.552 12 15 C 12 14.448 11.552 14 11 14 L 6 14 C 5.448 14 5 14.448 5 15 C 5 15.552 5.448 16 6 16 Z"
                  stroke-width=".1" />
          </svg>
        </div>
        <div class="list-title">{{ 'sidebar.member.appointments' | translate }}</div>
      </a>
      <a mat-list-item [routerLink]="['/visitor_history']" (click)="checkRestriction()" routerLinkActive="active-list-item">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
            <path d="M 12 2 C 9.436 2.007 6.973 2.999 5.120 4.770 L 5.120 3 C 5.120 2.448 4.672 2.000 4.120 2 C 3.568 2 3.120 2.448 3.120 3.000 L 3.120 7.500 C 3.120 8.052 3.568 8.500 4.120 8.500 L 8.620 8.500 C 9.172 8.500 9.620 8.052 9.620 7.500 C 9.620 6.948 9.172 6.500 8.620 6.500 L 6.220 6.500 C 8.872 3.728 13.118 3.230 16.341 5.311 C 19.563 7.392 20.854 11.468 19.418 15.025 C 17.983 18.583 14.224 20.620 10.460 19.881 C 6.695 19.143 3.985 15.836 4 12.000 C 4 11.448 3.552 11.000 3.000 11.000 C 2.448 11.000 2.000 11.448 2 12.000 C 2 17.523 6.477 22 12 22 C 17.523 22 22 17.523 22 12 C 22 6.477 17.523 2 12 2 Z M 12 8 C 11.448 8 11 8.448 11 9 L 11 12 C 11 12.552 11.448 13 12 13 L 14 13 C 14.552 13 15 12.552 15 12 C 15 11.448 14.552 11 14 11 L 13 11 L 13 9 C 13 8.448 12.552 8 12 8 Z"
                  stroke-width=".1" />
          </svg>
        </div>
        <div class="list-title">{{ 'sidebar.member.visitorHistories' | translate }}</div>
      </a>
      <a mat-list-item [routerLink]="['/event']" [disabled]="disabledMenu || organization?.is_holdings_role === 1" (click)="checkRestriction()" routerLinkActive="active-list-item">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20" height="22.189" viewBox="0 0 20 22.189" style="overflow: visible; position: absolute; left: 24px; top: 11px;">
            <path d="M509.155,807.309a1,1,0,0,1,1-1h14a1,1,0,0,1,1,1V810.3a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1v-2.994a3,3,0,0,0-3-3h-14a3,3,0,0,0-3,3V810.3a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1Z" transform="translate(-507.155 -789.114)" stroke-width=".1" />
            <rect width="2.078" height="4.947" rx="0.801" transform="translate(8.961 11.625)" stroke-width=".1" />
            <path d="M510.313,802.257h1.271a.5.5,0,0,0,.457-.318,5.518,5.518,0,0,1,10.228,0,.5.5,0,0,0,.457.318H524a.348.348,0,0,0,.335-.441,7.546,7.546,0,0,0-3.98-4.6,4.69,4.69,0,1,0-6.392,0,7.547,7.547,0,0,0-3.981,4.6A.348.348,0,0,0,510.313,802.257Zm6.315-11.092a2.692,2.692,0,1,1-2.113,2.111A2.695,2.695,0,0,1,516.628,791.165Z" transform="translate(-507.155 -789.114)" stroke-width=".1" />
          </svg>
        </div>
        <div class="list-title">{{ 'sidebar.member.events' | translate }}</div>
      </a>
      <a mat-list-item [routerLink]="['/location_reservation']" (click)="checkRestriction()" routerLinkActive="active-list-item">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" viewBox="0 0 20 22.189" style="width: 1px; height: 1px; overflow: visible; position: absolute; left: 22px; top: 9px;" xmlns="http://www.w3.org/2000/svg">
            <!-- <g> -->
              <path d="M47.436,302.806c26.222,0,47.417-21.236,47.417-47.436c0-26.192-21.195-47.437-47.417-47.437
                C21.236,207.932,0,229.178,0,255.37C0,281.57,21.236,302.806,47.436,302.806z"></path>
              <path d="M47.386,318.985c-25.506,0-46.324,20.679-46.324,46.314v57.588h54.876l35.408-72.328
                C85.278,332.106,67.946,318.985,47.386,318.985z"></path>
              <path d="M125.037,212.114c23.48,0,42.481-19.01,42.481-42.5c0-23.45-19.001-42.49-42.481-42.49
                c-23.47,0-42.49,19.04-42.49,42.49C82.547,193.104,101.568,212.114,125.037,212.114z"></path>
              <path d="M83.431,310.563v9.158h23.023l42.113-85.825c-6.684-4.708-14.739-7.3-23.53-7.3
                c-5.94,0-11.64,1.231-16.715,3.466c3.218,7.806,5.075,16.338,5.075,25.267C113.397,278.492,101.508,298.793,83.431,310.563z"></path>
              <path d="M250.989,129.825c23.48,0,42.49-19.01,42.49-42.5c0-23.45-19.01-42.49-42.49-42.49
                c-23.47,0-42.49,19.04-42.49,42.49C208.499,110.815,227.519,129.825,250.989,129.825z"></path>
              <path d="M250.989,144.276c-22.944,0-41.577,18.614-41.577,41.587v18.026h83.153v-18.026
                C292.566,162.89,273.962,144.276,250.989,144.276z"></path>
              <polygon points="176.149,219.871 66.437,443.745 66.437,467.166 445.563,467.166 445.563,443.745 335.851,219.871 	"></polygon>
              <path d="M464.563,302.806c26.202,0,47.437-21.236,47.437-47.436c0-26.192-21.235-47.437-47.437-47.437
                c-26.221,0-47.417,21.246-47.417,47.437C417.146,281.57,438.342,302.806,464.563,302.806z"></path>
              <path d="M464.613,318.985c-20.56,0-37.892,13.121-43.961,31.575l35.409,72.328h54.876v-57.588
                C510.937,339.664,490.119,318.985,464.613,318.985z"></path>
              <path d="M386.962,212.114c23.471,0,42.491-19.01,42.491-42.5c0-23.45-19.02-42.49-42.491-42.49
                c-23.48,0-42.48,19.04-42.48,42.49C344.482,193.104,363.482,212.114,386.962,212.114z"></path>
              <path d="M386.962,226.596c-8.789,0-16.844,2.592-23.529,7.3l42.113,85.825h23.024v-9.158
                c-18.078-11.77-29.966-32.071-29.966-55.234c0-8.929,1.857-17.461,5.075-25.267C398.603,227.826,392.902,226.596,386.962,226.596z"></path>
            <!-- </g> -->
          </svg>
          <!-- <img src="../../assets/img/location_reservation_icon@2x.svg"> -->
        </div>
        <div class="list-title">{{ 'sidebar.member.locationReservation' | translate }}</div>
      </a>
      <a mat-list-item [routerLink]="['/calendar-extension']" (click)="checkRestriction()" routerLinkActive="active-list-item">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
            <path d="M 12 19 C 12.552 19 13 18.552 13 18 C 13 17.448 12.552 17 12 17 C 11.448 17 11 17.448 11 18 C 11 18.552 11.448 19 12 19 Z M 17 19 C 17.552 19 18 18.552 18 18 C 18 17.448 17.552 17 17 17 C 16.448 17 16 17.448 16 18 C 16 18.552 16.448 19 17 19 Z M 17 15 C 17.552 15 18 14.552 18 14 C 18 13.448 17.552 13 17 13 C 16.448 13 16 13.448 16 14 C 16 14.552 16.448 15 17 15 Z M 12 15 C 12.552 15 13 14.552 13 14 C 13 13.448 12.552 13 12 13 C 11.448 13 11 13.448 11 14 C 11 14.552 11.448 15 12 15 Z M 19 3 L 18 3 L 18 2 C 18 1.448 17.552 1.000 17 1 C 16.448 1 16 1.448 16 2.000 L 16 3 L 8 3 L 8 2 C 8 1.448 7.552 1.000 7 1 C 6.448 1 6 1.448 6 2.000 L 6 3 L 5 3 C 3.343 3 2.000 4.343 2 6.000 L 2 20 C 2 21.657 3.343 23 5.000 23 L 19 23 C 20.657 23 22 21.657 22 20 L 22 6 C 22 4.343 20.657 3 19 3 Z M 20 20 C 20 20.552 19.552 21 19 21 L 5 21 C 4.448 21 4 20.552 4 20 L 4 11 L 20 11 L 20 20 Z M 20 9 L 4 9 L 4 6 C 4 5.448 4.448 5 5 5 L 6 5 L 6 6 C 6 6.552 6.448 7 7 7 C 7.552 7 8 6.552 8 6 L 8 5 L 16 5 L 16 6 C 16 6.552 16.448 7 17 7 C 17.552 7 18 6.552 18 6 L 18 5 L 19 5 C 19.552 5 20 5.448 20 6 L 20 9 Z M 7 15 C 7.552 15 8 14.552 8 14 C 8 13.448 7.552 13 7 13 C 6.448 13 6 13.448 6 14 C 6 14.552 6.448 15 7 15 Z M 7 19 C 7.552 19 8 18.552 8 18 C 8 17.448 7.552 17 7 17 C 6.448 17 6 17.448 6 18 C 6 18.552 6.448 19 7 19 Z"
                  stroke-width=".1" />
          </svg>
        </div>
      <div class="list-title">{{ 'sidebar.member.calendar-extension' | translate }}</div>
      </a>
      <ng-container *ngIf="userService.isAdmin | async">
        <mat-divider></mat-divider>
        <div class="admin-menu-title">{{ 'sidebar.admin.menuTitle' | translate }}</div>
        <a mat-list-item [routerLink]="['/organization_setting']" [disabled]="disabledMenu" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
              <path d="M 12 8 C 9.791 8 8 9.791 8 12 C 8 14.209 9.791 16 12 16 C 14.209 16 16 14.209 16 12 C 16 9.791 14.209 8 12 8 Z M 12 14 C 10.895 14 10 13.105 10 12 C 10 10.895 10.895 10 12 10 C 13.105 10 14 10.895 14 12 C 14 13.105 13.105 14 12 14 Z M 21.710 11.290 L 19.360 9 L 19.360 5.640 C 19.360 5.088 18.912 4.640 18.360 4.640 L 15.050 4.640 L 12.710 2.290 C 12.522 2.101 12.267 1.994 12 1.994 C 11.733 1.994 11.478 2.101 11.290 2.290 L 9 4.640 L 5.640 4.640 C 5.088 4.640 4.640 5.088 4.640 5.640 L 4.640 9 L 2.290 11.290 C 2.101 11.478 1.994 11.733 1.994 12 C 1.994 12.267 2.101 12.522 2.290 12.710 L 4.640 15.050 L 4.640 18.360 C 4.640 18.912 5.088 19.360 5.640 19.360 L 9 19.360 L 11.340 21.710 C 11.528 21.899 11.783 22.006 12.050 22.006 C 12.317 22.006 12.572 21.899 12.760 21.710 L 15.100 19.360 L 18.410 19.360 C 18.962 19.360 19.410 18.912 19.410 18.360 L 19.410 15.050 L 21.760 12.710 C 21.943 12.516 22.040 12.256 22.031 11.990 C 22.022 11.723 21.906 11.471 21.710 11.290 Z M 17.660 13.930 C 17.469 14.117 17.361 14.373 17.360 14.640 L 17.360 17.360 L 14.640 17.360 C 14.373 17.361 14.117 17.469 13.930 17.660 L 12 19.590 L 10.070 17.660 C 9.883 17.469 9.627 17.361 9.360 17.360 L 6.640 17.360 L 6.640 14.640 C 6.639 14.373 6.531 14.117 6.340 13.930 L 4.410 12 L 6.340 10.070 C 6.531 9.883 6.639 9.627 6.640 9.360 L 6.640 6.640 L 9.360 6.640 C 9.627 6.639 9.883 6.531 10.070 6.340 L 12 4.410 L 13.930 6.340 C 14.117 6.531 14.373 6.639 14.640 6.640 L 17.360 6.640 L 17.360 9.360 C 17.361 9.627 17.469 9.883 17.660 10.070 L 19.590 12 L 17.660 13.930 Z"
                    stroke-width=".1" />
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.receptionSettings' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/user_setting']" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute; left: 24px; top: 9.5px;">
              <path d="M 21 10.500 L 20 10.500 L 20 9.500 C 20 8.948 19.552 8.500 19 8.500 C 18.448 8.500 18 8.948 18 9.500 L 18 10.500 L 17 10.500 C 16.448 10.500 16 10.948 16 11.500 C 16 12.052 16.448 12.500 17 12.500 L 18 12.500 L 18 13.500 C 18 14.052 18.448 14.500 19 14.500 C 19.552 14.500 20 14.052 20 13.500 L 20 12.500 L 21 12.500 C 21.552 12.500 22 12.052 22 11.500 C 22 10.948 21.552 10.500 21 10.500 Z M 13.300 12.220 C 14.380 11.285 15.000 9.928 15 8.500 C 15 5.739 12.761 3.500 10.000 3.500 C 7.239 3.500 5.000 5.739 5 8.500 C 5 9.928 5.620 11.285 6.700 12.220 C 3.841 13.515 2.003 16.361 2 19.500 C 2 20.052 2.448 20.500 3 20.500 C 3.552 20.500 4 20.052 4 19.500 C 4 16.186 6.686 13.500 10.000 13.500 C 13.314 13.500 16 16.186 16 19.500 C 16 20.052 16.448 20.500 17 20.500 C 17.552 20.500 18 20.052 18 19.500 C 17.997 16.361 16.159 13.515 13.300 12.220 Z M 10 11.500 C 8.343 11.500 7 10.157 7 8.500 C 7 6.843 8.343 5.500 10 5.500 C 11.657 5.500 13 6.843 13 8.500 C 13 10.157 11.657 11.500 10 11.500 Z"
                    stroke-width=".1" />
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.users' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/organization_management']" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <mat-icon svgIcon="organization"></mat-icon>
          </div>
          <div class="list-title">{{ 'sidebar.admin.organizationManagement' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/office']" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <!-- <img height="30" src="../../assets/img/office.svg" routerLinkActive="active-list-item"> -->
            <svg class="list-icon" routerLinkActive="active-list-item" x="0px" y="0px" viewBox="0 0 512 512" style="overflow: visible; position: absolute; width: 23px; height: 25px; margin-left: 1px; opacity: 1;">
              <g>
                <path d="M49.548,0v512h412.903v-16.516V0H49.548z M429.419,478.968H280.774v-49.548v-49.548h-49.548v49.548v49.548
                  H82.58V33.032h346.839V478.968z"></path>
                <rect x="132.129" y="82.581" width="49.548" height="49.548"></rect>
                <rect x="231.226" y="82.581" width="49.548" height="49.548"></rect>
                <rect x="330.322" y="82.581" width="49.548" height="49.548"></rect>
                <rect x="132.129" y="181.678" width="49.548" height="49.548"></rect>
                <rect x="231.226" y="181.678" width="49.548" height="49.548"></rect>
                <rect x="330.322" y="181.678" width="49.548" height="49.548"></rect>
                <rect x="132.129" y="280.774" width="49.548" height="49.548"></rect>
                <rect x="231.226" y="280.774" width="49.548" height="49.548"></rect>
                <rect x="330.322" y="280.774" width="49.548" height="49.548"></rect>
                <rect x="132.129" y="379.871" width="49.548" height="49.548"></rect>
                <rect x="330.322" y="379.871" width="49.548" height="49.548"></rect>
              </g>
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.offices' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/division']" (click)="checkRestriction()" routerLinkActive="active-list-item-fill">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item-fill" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="overflow: visible; position: absolute; width: 25px; height: 23px; opacity: 1;">
              <g>
                <path d="M147.57,320.188c-0.078-0.797-0.328-1.531-0.328-2.328v-6.828c0-3.25,0.531-6.453,1.594-9.5 c0,0,17.016-22.781,25.063-49.547c-8.813-18.594-16.813-41.734-16.813-64.672c0-5.328,0.391-10.484,0.938-15.563 c-11.484-12.031-27-18.844-44.141-18.844c-35.391,0-64.109,28.875-64.109,73.75c0,35.906,29.219,74.875,29.219,74.875 c1.031,3.047,1.563,6.25,1.563,9.5v6.828c0,8.516-4.969,16.266-12.719,19.813l-46.391,18.953 C10.664,361.594,2.992,371.5,0.852,383.156l-0.797,10.203c-0.406,5.313,1.406,10.547,5.031,14.438 c3.609,3.922,8.688,6.125,14.016,6.125H94.93l3.109-39.953l0.203-1.078c3.797-20.953,17.641-38.766,36.984-47.672L147.57,320.188z" style="fill: #101010;"></path>
                <path d="M511.148,383.156c-2.125-11.656-9.797-21.563-20.578-26.531l-46.422-18.953 c-7.75-3.547-12.688-11.297-12.688-19.813v-6.828c0-3.25,0.516-6.453,1.578-9.5c0,0,29.203-38.969,29.203-74.875 c0-44.875-28.703-73.75-64.156-73.75c-17.109,0-32.625,6.813-44.141,18.875c0.563,5.063,0.953,10.203,0.953,15.531 c0,22.922-7.984,46.063-16.781,64.656c8.031,26.766,25.078,49.563,25.078,49.563c1.031,3.047,1.578,6.25,1.578,9.5v6.828 c0,0.797-0.266,1.531-0.344,2.328l11.5,4.688c20.156,9.219,34,27.031,37.844,47.984l0.188,1.094l3.094,39.969h75.859 c5.328,0,10.406-2.203,14-6.125c3.625-3.891,5.438-9.125,5.031-14.438L511.148,383.156z" style="fill: #101010;"></path>
                <path d="M367.867,344.609l-56.156-22.953c-9.375-4.313-15.359-13.688-15.359-23.969v-8.281 c0-3.906,0.625-7.797,1.922-11.5c0,0,35.313-47.125,35.313-90.594c0-54.313-34.734-89.234-77.594-89.234 c-42.844,0-77.594,34.922-77.594,89.234c0,43.469,35.344,90.594,35.344,90.594c1.266,3.703,1.922,7.594,1.922,11.5v8.281 c0,10.281-6.031,19.656-15.391,23.969l-56.156,22.953c-13.047,5.984-22.344,17.984-24.906,32.109l-2.891,37.203h139.672h139.672 l-2.859-37.203C390.211,362.594,380.914,350.594,367.867,344.609z" style="fill: #101010;"></path>
              </g>
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.divisions' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/location']" [disabled]="disabledMenu" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item" width="22.552" height="21.461" viewBox="0 0 22.552 21.461" style="overflow: visible; position: absolute; left: 26px; top: 12.5px">
              <g transform="translate(0.95 0.95)">
                <path d="M83.758,153.618H96.205v17.093H92.54" transform="translate(-83.758 -153.618)" fill="none" stroke-linejoin="round" stroke-width="1.9"/>
                <path d="M92.54,173.179l-8.782-2.285V153.618L92.54,155.9Z" transform="translate(-83.758 -153.618)" fill="none" stroke-linejoin="round" stroke-width="1.9"/>
                <rect width="1.306" height="3.395" rx="0.653" transform="translate(5.069 8.583)" />
                <path d="M366.907,283.051l-1.839,1.8,1.839,1.943" transform="translate(-349.04 -276.456)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"/>
                <line x1="4.628" transform="translate(16.174 8.468)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"/>
              </g>
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.meetingRooms' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/supply']" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
              <path d="M 21.396 7.817 C 21.390 7.789 21.390 7.760 21.396 7.733 C 21.391 7.708 21.391 7.683 21.396 7.659 L 21.396 7.564 L 21.333 7.406 L 21.333 7.406 L 21.333 7.406 L 21.333 7.406 L 21.333 7.406 C 21.308 7.363 21.276 7.324 21.238 7.290 L 21.144 7.206 L 21.144 7.206 L 21.091 7.206 L 21.091 7.206 L 16.939 4.582 L 16.939 4.582 L 13.020 2.159 C 12.929 2.087 12.825 2.033 12.714 2.001 L 12.630 2.001 C 12.536 1.985 12.440 1.985 12.345 2.001 L 12.240 2.001 C 12.118 2.028 12.000 2.074 11.892 2.138 L 4.021 7.037 L 3.926 7.111 L 3.832 7.195 L 3.726 7.269 L 3.674 7.332 L 3.674 7.332 L 3.610 7.490 L 3.610 7.585 L 3.610 7.648 C 3.600 7.718 3.600 7.789 3.610 7.859 L 3.610 17.058 C 3.610 17.422 3.797 17.760 4.106 17.953 L 12.008 22.842 L 12.008 22.842 L 12.008 22.842 L 12.166 22.906 L 12.251 22.906 C 12.429 22.962 12.620 22.962 12.798 22.906 L 12.883 22.906 L 13.041 22.842 L 13.041 22.842 L 13.041 22.842 L 20.880 18.027 C 21.189 17.834 21.376 17.495 21.375 17.131 L 21.375 7.933 C 21.375 7.933 21.396 7.859 21.396 7.817 Z M 12.451 4.287 L 14.326 5.446 L 8.436 9.092 L 6.550 7.933 L 12.451 4.287 Z M 11.397 20.092 L 5.602 16.552 L 5.602 9.819 L 11.397 13.401 L 11.397 20.092 Z M 12.451 11.547 L 10.438 10.335 L 16.328 6.679 L 18.351 7.933 L 12.451 11.547 Z M 19.300 16.520 L 13.504 20.124 L 13.504 13.401 L 19.300 9.819 L 19.300 16.520 Z"
                    stroke-width=".1" />
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.supplies' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/device_management']" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item" x="0px" y="0px" viewBox="0 0 512 512" style="overflow: visible; position: absolute; width: 25px; height: 22px; top: 11px; opacity: 1;">
              <g>
                <path d="M440.488,0H71.512c-13.129,0-23.81,10.683-23.81,23.812v464.38c0,13.129,10.681,23.808,23.81,23.808h368.976
                  c13.129,0,23.81-10.679,23.81-23.808V23.812C464.298,10.683,453.617,0,440.488,0z M277.019,454.469
                  c0,11.603-9.429,21.04-21.019,21.04c-11.59,0-21.019-9.437-21.019-21.04c0-11.603,9.429-21.04,21.019-21.04
                  C267.59,433.428,277.019,442.866,277.019,454.469z M89.639,405.373V50.058h332.722v355.315H89.639z"></path>
              </g>
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.deviceManagement' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/external_linkage']" [disabled]="disabledMenu" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="overflow: visible; position: absolute; width: 20px; height: 24px; margin-left: 2.5px; opacity: 1;">
              <g>
                <path d="M509.681,102.798c-4.641-24-16.344-47.141-34.875-65.578C462.462,24.86,448.087,15.501,432.79,9.298 c-22.984-9.297-48.047-11.609-72.047-7c-23.953,4.641-47.047,16.391-65.578,34.922l-98.578,98.594 c-0.516,0.484-0.984,1-1.438,1.531c0.172-0.031,0.328-0.094,0.5-0.094c28.969-5.797,58.734-3.094,86.125,7.828 c0.141,0.031,0.297,0.125,0.438,0.172l60.516-60.516c5.906-5.891,12.578-10.203,19.781-13.109 c10.719-4.375,22.609-5.484,33.922-3.266c11.359,2.188,21.984,7.594,30.813,16.375c5.906,5.906,10.234,12.578,13.156,19.766 c4.375,10.719,5.469,22.625,3.266,33.953c-2.219,11.344-7.594,21.969-16.422,30.797l-98.609,98.625 c-5.891,5.906-12.547,10.234-19.734,13.156c-10.75,4.359-22.672,5.438-33.984,3.266c-11.297-2.219-21.953-7.594-30.813-16.422 c-4.766-4.797-8.484-10.078-11.297-15.75c-0.281-0.063-0.578-0.156-0.875-0.203c-6.578-1.234-13.625-0.547-19.797,2.031 c-4.266,1.781-8.141,4.359-11.484,7.734l-1.922,1.953c-10,12.125-17.875,29.266-2.109,51.828 c12.328,12.297,26.672,21.656,42,27.859c22.984,9.328,47.984,11.609,71.984,6.969c23.969-4.609,47.063-16.328,65.594-34.891 l98.609-98.594c12.313-12.328,21.688-26.719,27.891-42.047C512.009,151.813,514.306,126.782,509.681,102.798z"></path>
                <path d="M230.259,366.907c-0.188-0.094-0.313-0.125-0.469-0.188l-60.516,60.531 c-5.891,5.906-12.563,10.203-19.734,13.156c-10.75,4.375-22.641,5.438-33.953,3.266c-11.328-2.219-21.984-7.594-30.813-16.422 c-5.906-5.922-10.219-12.563-13.156-19.766c-4.359-10.719-5.453-22.609-3.25-33.953c2.188-11.328,7.578-21.953,16.406-30.797 l98.625-98.641c5.875-5.891,12.547-10.188,19.734-13.109c10.734-4.406,22.609-5.469,33.969-3.297 c11.313,2.234,21.953,7.594,30.781,16.406c4.797,4.781,8.516,10.109,11.344,15.781c0.266,0.078,0.563,0.141,0.859,0.219 c6.594,1.203,13.641,0.484,19.766-2.047c4.313-1.797,8.172-4.406,11.516-7.781l1.922-1.922c10-12.109,17.844-29.281,2.109-51.828 c-12.297-12.313-26.672-21.641-41.984-27.859c-23-9.297-48-11.609-72.031-6.984c-23.969,4.625-47.047,16.391-65.594,34.906 l-98.578,98.625c-12.344,12.266-21.719,26.703-27.891,42.031c-9.328,22.953-11.641,47.984-6.984,71.953 c4.641,24.047,16.359,47.094,34.875,65.594c12.328,12.359,26.719,21.719,42.031,27.938c22.984,9.313,48,11.594,72,6.953 c24-4.609,47.078-16.313,65.578-34.891l98.609-98.641c0.516-0.453,0.984-1,1.453-1.453c-0.156,0-0.344,0.063-0.5,0.063 C287.399,380.517,257.634,377.829,230.259,366.907z"></path>
              </g>
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.externalLinkage' | translate }}</div>
        </a>
        <a *ngIf="paymentMethod !== 'credit_card'" mat-list-item [routerLink]="['/change_payment_method']" (click)="checkRestriction()" routerLinkActive="active-list-item">
            <div class="list-item">
              <svg class="list-icon" routerLinkActive="active-list-item" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="overflow: visible; position: absolute; width: 22px; height: 22px; margin-left: 2.5px; opacity: 1;">
                <g>
                  <path class="st0" d="M443.969,76H68.031C30.469,76,0,106.469,0,144.031v223.938C0,405.531,30.469,436,68.031,436h375.938
                    C481.531,436,512,405.531,512,367.969V144.031C512,106.469,481.531,76,443.969,76z M480,367.969
                    C480,387.844,463.844,404,443.969,404H68.031C48.156,404,32,387.844,32,367.969V224h448V367.969z M480,176H32v-31.969
                    C32,124.156,48.156,108,68.031,108h375.938C463.844,108,480,124.156,480,144.031V176z" style="fill: rgb(75, 75, 75);"></path>
                  <rect x="104" y="289.328" class="st0" width="64" height="64" style="fill: rgb(75, 75, 75);"></rect>
                  <rect x="260" y="289.328" class="st0" width="160" height="24" style="fill: rgb(75, 75, 75);"></rect>
                  <rect x="260" y="337.328" class="st0" width="128" height="24" style="fill: rgb(75, 75, 75);"></rect>
                </g>
              </svg>
            </div>
            <div class="list-title">{{ 'sidebar.admin.paymentMethod' | translate }}</div>
        </a>
        <a *ngIf="paymentMethod === 'credit_card'" mat-list-item [routerLink]="['/plan']" (click)="checkRestriction()" routerLinkActive="active-list-item">
          <div class="list-item">
            <svg class="list-icon" routerLinkActive="active-list-item" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="overflow: visible; position: absolute; width: 22px; height: 22px; margin-left: 2.5px; opacity: 1;">
              <g>
                <path class="st0" d="M443.969,76H68.031C30.469,76,0,106.469,0,144.031v223.938C0,405.531,30.469,436,68.031,436h375.938
                  C481.531,436,512,405.531,512,367.969V144.031C512,106.469,481.531,76,443.969,76z M480,367.969
                  C480,387.844,463.844,404,443.969,404H68.031C48.156,404,32,387.844,32,367.969V224h448V367.969z M480,176H32v-31.969
                  C32,124.156,48.156,108,68.031,108h375.938C463.844,108,480,124.156,480,144.031V176z" style="fill: rgb(75, 75, 75);"></path>
                <rect x="104" y="289.328" class="st0" width="64" height="64" style="fill: rgb(75, 75, 75);"></rect>
                <rect x="260" y="289.328" class="st0" width="160" height="24" style="fill: rgb(75, 75, 75);"></rect>
                <rect x="260" y="337.328" class="st0" width="128" height="24" style="fill: rgb(75, 75, 75);"></rect>
              </g>
            </svg>
          </div>
          <div class="list-title">{{ 'sidebar.admin.creditCard' | translate }}</div>
        </a>
        <a *ngIf="paymentMethod === 'credit_card'" mat-list-item (click)="navigateToRakunekoContact()">
          <div class="list-item">
            <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 22px; height: 22px; opacity: 1;" xml:space="preserve">
              <style type="text/css">
                  .st0{fill:#4B4B4B;}
              </style>
              <g>
                  <path class="st0" d="M510.678,112.275c-2.308-11.626-7.463-22.265-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345
                      c-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557,0-37.395,7.996-50.21,20.814c-1.715,1.715-3.301,3.43-4.823,5.345
                      C8.785,90.009,3.63,100.649,1.386,112.275C0.464,116.762,0,121.399,0,126.087V385.92c0,9.968,2.114,19.55,5.884,28.203
                      c3.497,8.26,8.653,15.734,14.926,22.001c1.59,1.586,3.169,3.044,4.892,4.494c12.286,10.175,28.145,16.32,45.319,16.32h369.958
                      c17.18,0,33.108-6.145,45.323-16.384c1.718-1.386,3.305-2.844,4.891-4.43c6.27-6.267,11.425-13.741,14.994-22.001v-0.064
                      c3.769-8.653,5.812-18.171,5.812-28.138V126.087C512,121.399,511.543,116.762,510.678,112.275z M46.509,101.571
                      c6.345-6.338,14.866-10.175,24.512-10.175h369.958c9.646,0,18.242,3.837,24.512,10.175c1.122,1.129,2.179,2.387,3.112,3.637
                      L274.696,274.203c-5.348,4.687-11.954,7.002-18.696,7.002c-6.674,0-13.276-2.315-18.695-7.002L43.472,105.136
                      C44.33,103.886,45.387,102.7,46.509,101.571z M36.334,385.92V142.735L176.658,265.15L36.405,387.435
                      C36.334,386.971,36.334,386.449,36.334,385.92z M440.979,420.597H71.021c-6.281,0-12.158-1.651-17.174-4.552l147.978-128.959
                      l13.815,12.018c11.561,10.046,26.028,15.134,40.36,15.134c14.406,0,28.872-5.088,40.432-15.134l13.808-12.018l147.92,128.959
                      C453.137,418.946,447.26,420.597,440.979,420.597z M475.666,385.92c0,0.529,0,1.051-0.068,1.515L335.346,265.221L475.666,142.8
                      V385.92z" style="fill: rgb(75, 75, 75);"></path>
              </g>
          </svg>
          </div>
          <div class="list-title">{{ 'sidebar.contact' | translate }}</div>
          <img class="arrow" src="../../assets/img/arrow.png">
        </a>
      </ng-container>
      <mat-divider></mat-divider>
      <div class="other-menu-title"></div>
      <a mat-list-item (click)="logout()">
        <div class="list-item">
          <svg class="list-icon" routerLinkActive="active-list-item" width="20px" height="100%" x="0px" y="0px" style="overflow: visible; position: absolute;">
            <path d="M 12.590 13 L 10.290 15.290 C 10.101 15.478 9.994 15.733 9.994 16 C 9.994 16.267 10.101 16.522 10.290 16.710 C 10.478 16.899 10.733 17.006 11 17.006 C 11.267 17.006 11.522 16.899 11.710 16.710 L 15.710 12.710 C 15.801 12.615 15.872 12.503 15.920 12.380 C 16.020 12.137 16.020 11.863 15.920 11.620 C 15.872 11.497 15.801 11.385 15.710 11.290 L 11.710 7.290 C 11.318 6.898 10.682 6.898 10.290 7.290 C 9.898 7.682 9.898 8.318 10.290 8.710 L 12.590 11 L 3 11 C 2.448 11 2 11.448 2 12 C 2 12.552 2.448 13 3 13 L 12.590 13 Z M 12 2 C 8.187 1.983 4.697 4.136 3.000 7.550 C 2.751 8.047 2.953 8.651 3.450 8.900 C 3.947 9.149 4.551 8.947 4.800 8.450 C 6.550 4.917 10.613 3.185 14.373 4.370 C 18.134 5.555 20.470 9.303 19.879 13.201 C 19.287 17.099 15.943 19.985 12 20 C 8.957 20.013 6.175 18.284 4.840 15.550 C 4.591 15.053 3.987 14.851 3.490 15.100 C 2.993 15.349 2.791 15.953 3.040 16.450 C 5.234 20.866 10.320 23.023 15.020 21.532 C 19.720 20.040 22.631 15.345 21.878 10.472 C 21.124 5.599 16.931 2.002 12.000 2 Z"
                  stroke-width=".1" />
          </svg>
        </div>
      <div class="list-title">{{ 'sidebar.logout' | translate }}</div>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar #toolbar class="nav-header">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <ng-container *ngIf="hasMultipleOrganizations">
        <form [formGroup]="formGroup">
          <mat-form-field class="nav-toolbar-contents" >
            <mat-select [(value)]="organization" (selectionChange)="organizationChanged($event)" (closed)="onFocusOutEvent()">
              <mat-optgroup>
                <mat-form-field class="input-filtering-field">
                  <input matInput type="text"
                         formControlName="inputFilteringVal"
                         (keyup)="onOrganizationFilterChangeEvent($event)"
                         autocomplete="off">
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-option *ngFor="let org of filteredAvailableOrganizations" [value]="org">
                  {{org.name}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </form>
      </ng-container>
      <ng-container *ngIf="organization && !hasMultipleOrganizations">
        <span class="nav-toolbar-contents">{{organization.name}}</span>
      </ng-container>
      <span class="flexExpand"></span>
      <ng-container>
        <!-- <mat-form-field appearance="fill"> -->
          <mat-label class="lang-label">language：</mat-label>
          <mat-select [(ngModel)]="selected" name="lang" (selectionChange)="changeLang($event)" class="lang-selector">
            <mat-option *ngFor="let lang of languages; let i = index;" [value]="lang.lang">
              <span translate>{{lang.label}}</span>
            </mat-option>
          </mat-select>
        <!-- </mat-form-field> -->
      </ng-container>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
