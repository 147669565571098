import { Component, OnInit, OnDestroy, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IdentityProvider } from '../../auth/identity-provider.enum';
import { AuthProvider } from '../../auth/auth-provider.enum';
import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../core/user.service';

declare var fillDiv: any;

@Component({
    selector: 'app-restrict',
    templateUrl: './restrict.component.html',
    styleUrls: ['./restrict.component.scss']
})
export class RestrictComponent implements OnInit, OnDestroy {
    public readonly version = environment.version;

    resetForm: UntypedFormGroup;
    code = new UntypedFormControl('', [Validators.required]);

    loading = false;
    submitted = false;
    userNotFound = false;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
    ) {
    }

    ngOnInit() {

        this.resetForm = new UntypedFormGroup({
            code: new UntypedFormControl('', [Validators.required])
        });

        fillDiv();
        setTimeout(() => fillDiv());
    }

    ngOnDestroy() {}

    get environmentName(): string {
        return (environment.name && environment.name !== 'Production') ? '(' + environment.name + ')' : '';
    }

    public hasError(controlName: string, errorName: string): boolean {
        return this.resetForm.controls[controlName].hasError(errorName);
    }

    get f(): any { return this.resetForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.resetForm.invalid) {
            return;
        }
        if (this.loading) {
            return;
        }

        this.loading = true;

        this.userService.forgotPassword(this.f.code.value).subscribe(
            result => {
                this.loading = false;
                this.submitted = false;
                this.router.navigate(['/login/reset'], { queryParams: { code: this.f.code.value } });
            },
            error => {
                console.error(error);
                this.userNotFound = true;
                this.loading = false;
            }
        );
    }
}
