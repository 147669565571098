import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../core/user.service';
import { CustomValidators } from '../_helpers/custom-validators';

declare var fillDiv: any;

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    private queryParams: any;
    email: string;

    resetForm: UntypedFormGroup;

    loading = false;
    submitted = false;
    failed = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(
            params => {
                this.queryParams = params;
                this.email = this.queryParams.email;

                if (!this.email) {
                    this.router.navigate(['/login/forgot']);
                }
            }
        );

        this.resetForm = this.formBuilder.group({
            code: [
                '',
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(/\d/, {
                        hasNumber: true
                    }),
                    // CustomValidators.integer()
                ])
            ],
            password: [
                '',
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(/\d/, {
                        hasNumber: true
                    }),
                    CustomValidators.patternValidator(/[A-Z]/, {
                        hasCapitalCase: true
                    }),
                    CustomValidators.patternValidator(/[a-z]/, {
                        hasSmallCase: true
                    }),
                    //   CustomValidators.patternValidator(
                    //     /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
                    //         hasSpecialCharacters: false
                    //     }
                    //   ),
                    Validators.minLength(8)
                ])
            ],
            confirmPassword: ['', Validators.required],
        }, {
            validator: CustomValidators.passwordMatchValidator
        });

        fillDiv();
        setTimeout(() => fillDiv());
    }

    ngOnDestroy() {}

    public hasError(controlName: string, errorName: string): boolean {
        return this.resetForm.controls[controlName].hasError(errorName);
    }

    get f(): any { return this.resetForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.resetForm.invalid) {
            console.log(this.resetForm);
            return;
        }
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.userService.confirmNewPassword(this.email, this.f.code.value, this.f.password.value).subscribe(
            () => {
                console.log("password change complete");
                this.router.navigate(['/login']);
            },
            error => {
                console.error("password change failed: " + error);
                this.loading = false;
                this.failed = true;
            },
        );

    }

}
