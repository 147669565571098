<div id="fill">
  <div id="container" class="artboard scene">
    <div id="primary">
      <div id="primary_outer"></div>
      <div id="primary_inner"></div>
    </div>

    <div id="logo">
      <img src="../../assets/img/RCP_logo.png"
           srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
    </div>

    <div id="label_restrict">許可されていないIPアドレスからのアクセスです</div>
    <div id="label_description">
      ご利用設定で許可されていないIPアドレスからのアクセスです。<br />
      組織内の管理者にお問合せください。  
    </div>

    <div id="label_back_login">
      <a href="login">＜ログインへ戻る</a>
    </div>
  </div>
</div>
