import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of as observableOf, iif } from 'rxjs';
import { concatMap, switchMap, expand, map, reduce, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AkerunManagement } from './akerun-management';
import { AkerunDoorManagement } from './akerun-door-management';
import { OfficesMasterData } from './offices-master-data';

@Injectable({
    providedIn: 'root'
})

export class AkerunService {

    // private setting: AkerunSetting;
    private akerunManagement: AkerunManagement;
    private akerunDoorManagement: AkerunDoorManagement[];
    private officesMasterData: OfficesMasterData[];

    private readonly endpointUrl = environment.apiBaseUrl + '/akerun/setting';
    private readonly officeMasterDatatUrl = environment.apiBaseUrl + '/office';

    constructor(
        private http: HttpClient,
    ) {
    }

    accessToken(code: string): Observable<AkerunManagement | undefined> {
        // access code from akerun authorization callback
        if (code && code.length > 0) {

            const httpParams = new HttpParams()
                .append('grant_type', 'authorization_code')
                .append('client_id', environment.akerunSettings.ClientId)
                .append('client_secret', environment.akerunSettings.ClientSecret)
                .append('code', code)
                .append('redirect_uri', environment.akerunSettings.RedirectUri);

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                })
            };

            const endpointUrl = environment.akerunSettings.AccessTokenUrl;

            const options = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            return this.http.post(this.endpointUrl, {code: code}, options).pipe(
            // return this.http.post(endpointUrl, httpParams.toString(), httpOptions).pipe(
                map((result: { access_token: string, token_type: string, refresh_token: string, expires_in: number, created_at: number }) => {
                    const management: AkerunManagement = {
                        access_token: result.access_token,
                        token_type: result.token_type,
                        refresh_token: result.refresh_token,
                        token_expires_in: result.expires_in,
                        token_created_at: result.created_at
                    };

                    return management;
                })
            );
        }
        return observableOf(undefined);
    }

    organization(token: string): Observable<any | undefined> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        const endpointUrl = environment.akerunSettings.OrganizationsUrl;

        // organizations
        return this.http.get(this.endpointUrl + '?token=' + token, httpOptions).pipe(
            map(result => {
                // 初期実装は1件のみ
                let organization = (result as any).organizations[0];
                return organization;
            }),
            concatMap((organization: any) => {
                // organization detail
                return this.http.get(this.endpointUrl + '?organization_id=' + organization.id + '&token=' + token, httpOptions).pipe(
                    map(result => {
                        organization.name = (result as any).organization.name;
                        return organization;
                    }),
                    catchError((err) => {
                        console.error('error ->', err);
                        return observableOf(organization);
                    })
                )
            }),
            concatMap((organization: any) => {
                // akeruns
                return this.http.get(this.endpointUrl + '?organization_id=' + organization.id + '&token=' + token + '&akeruns=1', httpOptions).pipe(
                    map(result => {
                        organization.doors = (result as any).akeruns;
                        return organization;
                    }),
                    catchError((err) => {
                        return observableOf(organization);
                    })
                )
            }),
        );
        return observableOf(undefined);
    }

    public testAccessToken(code: string): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.endpointUrl, {code: code}, options).pipe(map(result => {
            return result;
        }));
        return observableOf(undefined);
    }

    public testUnlock(akerun_id: string): Observable<any> {
        const endpointUrl = environment.apiBaseUrl + '/akerun/' + akerun_id + '/unlock';
        return this.http.post(endpointUrl, null).pipe(map(result => {
            return result;
        }));
        return observableOf(undefined);
    }

    public testUnlockInfo(job_id: string): Observable<any> {
        const endpointUrl = environment.apiBaseUrl + '/akerun/unlock/' + job_id;
        return this.http.get(endpointUrl).pipe(map(result => {
            return result;
        }));
        return observableOf(undefined);
    }

    public current(forceLoad: boolean = false): Observable<AkerunManagement> {
        return (!this.akerunManagement || forceLoad) ? this.http.get(this.endpointUrl).pipe(map(result => {
            this.akerunManagement = result as AkerunManagement;
            return this.akerunManagement;
        })) : observableOf(this.akerunManagement);
    }

    public doors(): Observable<AkerunDoorManagement[]> {
        return this.http.get(environment.apiBaseUrl + '/akerun/door').pipe(map(result => {
            this.akerunDoorManagement = (result as any).doors;
            return this.akerunDoorManagement;
        }));
        return observableOf(this.akerunDoorManagement);
    }

    public update(data: AkerunManagement, forceLoad: boolean = false): Observable<AkerunManagement | undefined> {
        console.info("update");
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.put(this.endpointUrl, data, options).pipe(
            switchMap(
                result => {
                    const success = result && true && Object.assign<AkerunManagement, AkerunManagement>(this.akerunManagement, data);
                    return iif(() => success && true, this.current(forceLoad), observableOf(undefined));
                },
            )
        );

    }

    public updateDoor(akerunDoorManagement: AkerunDoorManagement): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(environment.apiBaseUrl + '/akerun/door/' + akerunDoorManagement.id, akerunDoorManagement, options);
    }

    public managementAdd(akerunManagement: AkerunManagement): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.endpointUrl, akerunManagement, options);
    }

    public managementChange(akerunManagement: AkerunManagement, orianizationId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(this.endpointUrl + '/' + orianizationId, akerunManagement, options);
    }

    public managementDelete(id: number): Observable<any> {
        return this.http.delete(this.endpointUrl + '/' + id);
    }

    public officesMasterDataGet(forceLoad: boolean = false): Observable<OfficesMasterData[]> {
        return (!this.officesMasterData || forceLoad) ? this.http.get(this.officeMasterDatatUrl).pipe(map(result => {
            this.officesMasterData = result["offices"] as OfficesMasterData[];
            return this.officesMasterData;
        })) : observableOf(this.officesMasterData);
    }

}
