import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            return null;
          }
      
          const valid = regex.test(control.value);
      
          return valid ? null : error;
        };
    }

    static passwordMatchValidator(control: AbstractControl) {
        const password: string = control.get('password').value;
        const confirmPassword: string = control.get('confirmPassword').value;
        // compare is the password math
        if (password !== confirmPassword) {
          control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
        }
    }

    static integer(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          const error: ValidationErrors = { integer: true };
      
          if (control.value && control.value !== `${parseInt(control.value, 10)}`) {
            control.setErrors(error);
            return error;
          }
      
        //   control.setErrors(null);
          return null;
        };
      }
}
