import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { OrganizationService } from '../../core/organization.service';
import { OrganizationAccessSettingService } from '../../core/organization-access-setting.service';
import { PlanService } from '../../core/credit.service';
import { ChangePaymentMethodService } from '../../core/change-payment-method.service';
import { changePaymentMethodManagement } from '../../core/change-payment-method-management';
import { UserService, AuthType } from '../../core/user.service';
import { Organization } from '../../core/organization';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../core/navigation.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { User } from 'src/app/core/user';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
    @ViewChild('drawer', { static: true }) drawer: ElementRef;

    public dynamicWidth: number = undefined;

    public readonly version = environment.version;

    private organizationSubscription: Subscription;
    private routeSubscription: Subscription;
    private usersSubscription: Subscription;
    private planSubscription: Subscription;
    formGroup: UntypedFormGroup;

    user: User;
    organization: Organization;
    availableOrganizations: Organization[];
    filteredAvailableOrganizations: Organization[];

    isHandset$: Observable<boolean>;

    languages = environment.languages;
    currentLang: string = environment.defaultLang;
    selected: string;
    disabledMenu: boolean = false;
    paymentMethod: string;
    changePaymentMethods: changePaymentMethodManagement[];
    displayChangePaymentMethod: any = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private renderer: Renderer2,
        private breakpointObserver: BreakpointObserver,
        private organizationService: OrganizationService,
        private organizationAccessSettingService: OrganizationAccessSettingService,
        private authService: AuthService,
        private translate: TranslateService,
        public userService: UserService,
        public planService: PlanService,
        public changePaymentMethodService: ChangePaymentMethodService,
        private navigationService: NavigationService,
        private formBuilder: UntypedFormBuilder,
        @Inject(DOCUMENT) private document: Document
      ) {
        this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
            .pipe(
                map(result => result.matches)
            );
        this.formGroup = this.formBuilder.group({
          inputFilteringVal: ['', { updateOn: 'blur' }],
        });
    }

    ngOnInit() {
        this.routeSubscription = this.route.data.subscribe(
            () => {
                this.loadUser().subscribe(() => {
                    this.loadOrganizations();
                });
            },
        );
        this.planSubscription = this.planService.contractGet().subscribe(entities => {
            // LPトライアル企業で、トライアル期間を過ぎて、クレジットカード未登録の企業はメニューを制御
            this.paymentMethod = entities.payment_method;
            this.disabledMenu = this.isTrialExpiredWithoutCreditCard(entities) || this.contractIsIncomplete(entities);

            this.planService.setContractData(entities);
        });
        this.changePaymentMethodService.changePaymentMethodGet().subscribe(entities => {
            this.displayChangePaymentMethod = entities[0].price !== null;
            console.log(this.displayChangePaymentMethod);
        });
        const sideWidth = this._getWidth();
        console.log('sideWidth: ' + sideWidth);
        // this.dynamicWidth = sideWidth;
    }

    private _getWidth(): number {
        const currentWidth = 1600;
        const sideWidth = 291;
        const availableWidth = window.innerWidth;
        const scaleX = availableWidth / currentWidth;

        return scaleX * sideWidth;
    }

    private isTrialExpiredWithoutCreditCard(entities) {
        return (entities.payment_method === 'credit_card' &&
                !entities.register_credit_card &&
                Date.now() > Date.parse(entities.trial_end));
    }

    private contractIsIncomplete(entities) {
        return (entities.payment_method === 'credit_card' &&
                entities.register_credit_card &&
                entities.rakuneko.users === 50 &&
                !Object.values(entities.rakuneko).includes(true) &&
                Date.now() > (Date.parse(entities.trial_end)+2592000000));
    }

    ngOnDestroy() {
        if (this.organizationSubscription) {
            this.organizationSubscription.unsubscribe();
        }
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
        if (this.planSubscription) {
            this.planSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    get environmentName(): string {
        return (environment.name && environment.name !== 'Production') ? '(' + environment.name + ')' : '';
    }

    get hasMultipleOrganizations(): boolean {
        return this.availableOrganizations && this.availableOrganizations.length > 1;
    }

    navigateToChromeExtensionForGoogleCalendar() {
        window.open(environment.chromeExtensionForGoogleCalendarUri, '_blank');
    }

    navigateToChromeExtensionForOutlookCalendar() {
        window.open(environment.chromeExtensionForOutlookCalendarUri, '_blank');
    }

    navigateToRakunekoContact() {
        window.open(environment.rakunekoContactUri, '_blank');
    }

    organizationChanged(event: MatSelectChange) {
        let url = this.router.url;
        const includeHoldings = this.user && this.user.is_holdings_administrator === 1;
        this.organizationSubscription = this.organizationService.reloadOrganizations(includeHoldings).pipe(
            map(organizations => organizations)
        ).subscribe(
            result => {
                this.organizationService.setCurrent(event.value);
                sessionStorage.setItem('organization', JSON.stringify(this.organization));

                // 検索条件を引き継いでしまうので?以降削除
                if (url.indexOf('appointment') !== -1 || url.indexOf('visitor_history') !== -1) {
                    if (url.indexOf('?') !== -1) {
                        url = url.substring(0, url.indexOf('?'));
                    }

                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate([url]);
                    });
                } else {
                    this.router.navigateByUrl(url, { replaceUrl: true });
                }
                this.navigationService.notifyOrganizationChanges();

                this.loadUser().subscribe(() => {
                    if (this.user.organization_id === this.organization.id) {
                        this.loadOrganizations();
                    } else {
                        this.loadHubSpot(this.organization);
                    }
                });
            }
        );
    }

    logout() {
        this.authService.logout();
        // this.router.navigate(['/'], { replaceUrl: true });
    }

    private loadOrganizations() {
        const includeHoldings = this.user && this.user.is_holdings_administrator === 1;
        this.organizationSubscription = this.organizationService.available(true, includeHoldings).pipe(
            switchMap(organizations => {
                this.availableOrganizations = organizations;
                this.filteredAvailableOrganizations = this.availableOrganizations;
                return this.organizationService.current(false, includeHoldings);
            }),
        ).subscribe(
            result => {
                // 前回切り替えていた組織の情報を取得
                const org = JSON.parse(sessionStorage.getItem('organization'));
                if (org && this.availableOrganizations.some(o => o.id === org.id)) {
                    this.organization = this.availableOrganizations.find(o => o.id === org.id);
                    this.organizationService.setCurrent(this.organization);
                } else {
                    this.organization = result;
                }
                this.checkRestriction();
                this.loadUser();
                this.loadHubSpot(this.organization);
            }
        );
    }

    checkRestriction() {
        this.organizationAccessSettingService.check().subscribe(
            result => {
                if (result.isRestricted) {
                    console.log('アクセス出来ません');
                    this.router.navigate(['/access/restrict']);
                }
            }
        );
    }

    private loadUser(): Observable<void> {
        return this.userService.current(true).pipe(
            map(user => {
                this.user = user;
                this.selected = user.lang;
                this.currentLang = user.lang;
                this.translate.use(user.lang);
    
                // MFA認証が組織全体で有効なら設定画面へ遷移させる
                const authType = localStorage.getItem('authType');
                if (authType !== AuthType.GOOGLE && this.organization?.mfa_enabled && !user.mfa_enabled)
                    this.router.navigate(['/mfa/select']);
            })
        );
    }

    // organizationの設定次第でHubSpotを読み込む
    private loadHubSpot(organization: Organization) {
        const hs = this.document.getElementById('hs-script-loader');
        const hsContainer = this.document.getElementById('hubspot-messages-iframe-container');
        let hsContainerStyle = 'initial';

        if (organization && organization.hubspot_enabled) {
            if (!hs) {
                /* Start of Generate HubSpot Embed Code */
                const hubspotScript = this.renderer.createElement('script');
                hubspotScript.type = 'text/javascript';
                hubspotScript.id = 'hs-script-loader';
                hubspotScript.src = '//js.hs-scripts.com/23839271.js';
                hubspotScript.async = true;
                hubspotScript.defer = true;
                this.renderer.appendChild(this.document.body, hubspotScript);
                /* End of Generate HubSpot Embed Code */
            }
        } else {
            hsContainerStyle = 'none';
        }
        if (hsContainer) hsContainer.style.setProperty('display', hsContainerStyle, 'important');
    }
    
    changeLang(event) {
        const selectedLang = event.value;
        if (selectedLang !== this.currentLang) {
            console.log('change lang: ' + selectedLang);
            this.currentLang = selectedLang;
            this.translate.use(selectedLang);

            this.usersSubscription = this.userService.update({
                lang: selectedLang
            }).subscribe(result => {
                console.log('user lang change success');
            });
        }
    }

    isCurrentLang(lang) {
        return lang === this.currentLang;
    }

    replaceFullToHalf(str) {
      return str.replace(/[！-～]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    }

    normalizeText(str) {
      return this.replaceFullToHalf(str).toUpperCase();
    }

    filteringSelectBoxOptions(str: string): void {
      const inputFilteringVal = this.normalizeText(str);
      if (inputFilteringVal) {
        this.filteredAvailableOrganizations =
          this.availableOrganizations.filter(option => this.normalizeText(option.name).indexOf(inputFilteringVal) >= 0);
      } else {
        this.filteredAvailableOrganizations = this.availableOrganizations;
      }
    }

    onOrganizationFilterChangeEvent(event: any): void {
      this.filteringSelectBoxOptions((event.target as HTMLInputElement).value);
    }

    onFocusOutEvent(): void {
      this.filteredAvailableOrganizations = this.availableOrganizations;
    }

}
