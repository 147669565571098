import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingPageRoutingModule } from './landing-page-routing.module';

@NgModule({
    declarations: [
        LandingPageComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        LandingPageRoutingModule,
    ]
})
export class LandingPageModule { }
