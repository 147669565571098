<div class="container">
  <div class="vertical-center">
    <p class="title">どの方法で認証コードを受け取りますか？</p>
    <button mat-button (click)="onClickSms()">
      <p class="btn-title">SMS</p>
      <p class="btn-detail">モバイル端末でテキストメッセージを受信します</p>
    </button><br />
    <button mat-button (click)="onClickApp()">
      <p class="btn-title">認証アプリ</p>
      <p class="btn-detail">Google Authenticatorなど、端末の認証アプリからコードを取得します</p>
    </button>
  </div>
</div>
