import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of as observableOf, iif } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as AWS from 'aws-sdk';
import {
    CognitoUserSession,
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    ICognitoUserPoolData,
    CognitoUserAttribute,
    ICognitoUserData,
    IAuthenticationDetailsData,
} from 'amazon-cognito-identity-js';
import { NewPasswordUser } from "../login/new-password/new-password.component";
import { environment } from '../../environments/environment';
import { User } from './user';
import { UserSetting } from './user-setting';
import { UserType } from './user-type.enum';
import { UserManagement } from './user-management';
import { UserGroup } from './user-group';
import { UserGroupMember } from './user-group-member';
import { OfficesMasterData } from './offices-master-data';
import { LocalStorageService } from '../../app/auth/local-storage.service';
import { Token } from '../../app/auth/token';

export enum AuthType {
    GOOGLE = 'google',
    IDTOKEN = 'idtoken',
    SMS = 'sms',
    TOTP = 'totp'
}

export interface UserResult {
    total_count: number;
    filtered_count: number;
    users: UserManagement[];
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private user: User;
    private userManagement: UserManagement[];
    private userGroup: UserGroup[];
    private userGroupMember: UserGroupMember[];

    private officesMasterData: OfficesMasterData[];


    session: CognitoUserSession;
    cognitoIdServiceProvider: AWS.CognitoIdentityServiceProvider;
    private userPool: CognitoUserPool;
    private poolData: ICognitoUserPoolData;
    private readonly endpointUrl = environment.apiBaseUrl + '/user';
    private readonly managementEndpointUrl = this.endpointUrl + '/management';
    private readonly officeMasterDatatUrl = environment.apiBaseUrl + '/office';
    private readonly groupEndpointUrl = this.endpointUrl + '/group';
    private readonly groupMemberEndpointUrl = this.endpointUrl + '/group/member';
    private readonly bulkEndpointUrl = this.managementEndpointUrl + '/bulk';
    private readonly bulkStatusEndpointUrl = this.bulkEndpointUrl + '/status';
    private readonly syncEndpointUrl = this.managementEndpointUrl + '/sync';

    constructor(
        private http: HttpClient,
        private router: Router,
        private localStorage: LocalStorageService
    ) {
        AWS.config.region = environment.cognitoSettings.Region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: environment.cognitoSettings.IdentityPoolId
        });
        this.poolData = {
            UserPoolId: environment.cognitoSettings.UserPoolId,
            ClientId: environment.cognitoSettings.ClientId,
        };
        this.userPool = new CognitoUserPool(this.poolData);
        this.cognitoIdServiceProvider = new AWS.CognitoIdentityServiceProvider({
            // accessKeyId: environment.awsConfig.accessKeyId,
            // secretAccessKey: environment.awsConfig.secretAccessKey,
            region: environment.cognitoSettings.Region
        });
        console.log("cognitoIdServiceProvider", this.cognitoIdServiceProvider);
        // this.userPool = CognitoUtils.getUserPool();
    }

    public current(forceLoad: boolean = false): Observable<User> {
        return (!this.user || forceLoad) ?
            this.http.get(this.endpointUrl).pipe(map(result => {
                    this.user = result as User;
                    return this.user;
                })) : observableOf(this.user);
    }

    public login(email: string, password: string): Observable<any | null> {
        const cognitoUser = this.getCognitoUser(email);
        const authenticationData: IAuthenticationDetailsData = {
            Username: email,
            Password: password,
        };
        // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        return Observable.create(observer => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: result => {
                    console.log('authenticateUser onSuccess');
                    this.session = result;
                    this.localStorage.setToken(new Token(result.getIdToken().getJwtToken(), result.getAccessToken().getJwtToken(), result.getRefreshToken().getToken()));
                    // this.router.navigate(['/account_setting']);
                    observer.next({authType: AuthType.IDTOKEN, cognitoUser});
                },
                mfaRequired: function(codeDeliveryDetails) {
                    console.log('authenticateUser mfaRequired');

                    observer.next({authType: AuthType.SMS, cognitoUser});
                },
                totpRequired: function (secretCode) {
                    console.log('authenticateUser totpRequired');
                    observer.next({authType: AuthType.TOTP, cognitoUser});
                },
                onFailure: err => {
                    console.log('authenticateUser onFailure');
                    observer.error(err.message);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    console.log('authenticateUser newPasswordRequired');
                    // this.router.navigate(['dashboard/login', { username: login }]);
                    observer.error('New Password Required');
                }
            });
        });
    }

    public get userId(): number {
        return this.user.id;
    }

    public get isAdmin(): Observable<boolean> {
        return this.current().pipe(
            map(user => user && user.user_type_id === UserType.Admin)
        );
    }

    public set imageUrl(url: string) {
        this.user.image_url = url;
    }

    public getByEmail(email: string, forceLoad: boolean = false): Observable<User> {
        console.log("getByEmail " + email);
        return (!this.user || forceLoad) ? this.http.get(this.endpointUrl + "?email=" + email).pipe(map(result => {
            this.user = result as User;
            console.log("user " + this.user);
            return this.user;
        })) : observableOf(this.user);
    }

    public update(data: UserSetting, forceLoad: boolean = false): Observable<User | undefined> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.put(this.endpointUrl, data, options).pipe(
            switchMap(
                result => {
                    const success = result && true && Object.assign<User, UserSetting>(this.user, data);
                    return iif(() => success && true, this.current(forceLoad), observableOf(undefined));
                },
            )
        );

    }

    /**
     * 来客通知情報一括更新(全ユーザー)
     *
     * @param data
     */
    public updateAll(data: any): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(this.endpointUrl, data, options).pipe(
            switchMap(
                result => {
                    const success = result && true;
                    return iif(() => success && true, observableOf(data), observableOf(undefined));
                },
            )
        );
    }

    /**
     * 管理者が社員情報を更新
     *
     * @param data
     * @param forceLoad
     */
    public updateByAdmin(data: any): Observable<User | undefined> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.put(this.endpointUrl, data, options).pipe(
            switchMap(
                result => {
                    const success = result && true;
                    return iif(() => success && true, observableOf(data), observableOf(undefined));
                },
            )
        );

    }

    public groupGet(forceLoad: boolean = false): Observable<UserGroup[]> {
        return (!this.userGroup || forceLoad) ? this.http.get(this.groupEndpointUrl).pipe(map(result => {
            this.userGroup = result as UserGroup[];
            return this.userGroup;
        })) : observableOf(this.userGroup);
    }

    public groupUpdate(data: UserGroup): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(this.groupEndpointUrl + '/' + data.group_id, {name: data.group_name}, options);
    }

    public groupAdd(name: string): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.groupEndpointUrl, {name: name}, options);
    }

    public groupDelete(groupId: number): Observable<any> {
        return this.http.delete(this.groupEndpointUrl + '/' + groupId);
    }

    public groupMemberGet(groupId: number, forceLoad: boolean = false): Observable<UserGroupMember[]> {
        return (!this.userGroupMember || forceLoad) ? this.http.get(this.groupMemberEndpointUrl + '/' + groupId).pipe(map(result => {
            this.userGroupMember = result as UserGroupMember[];
            return this.userGroupMember;
        })) : observableOf(this.userGroupMember);
    }

    public managementGet(forceLoad: boolean = false, filter?: string): Observable<UserManagement[]> {
        let params = {} as any;

        if (filter) {
            params['filter'] = filter;
        }

        return (!this.userManagement || forceLoad) ? this.http.get(this.managementEndpointUrl, {params}).pipe(map(result => {
            this.userManagement = result as UserManagement[];
            return this.userManagement;
        })) : observableOf(this.userManagement);
    }

    public paginatorGet(sort: string, order: string, page: number = 0, limit: number = 10, filter?: string): Observable<UserResult> {
        const params: { [key: string]: string } = {
            sort: '' + sort,
            order: '' + order,
            ...{ page: '' + page, limit: '' + limit },
            ...(filter && { filter: filter }),
        };
        return this.http.get(this.managementEndpointUrl, { params }).pipe(map(result => {
            return result as UserResult;
        }));
    }

    public managementAdd(userManagements: UserManagement[]): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.managementEndpointUrl, userManagements, options);
    }

    public managementChange(userManagement: UserManagement, userId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(this.managementEndpointUrl + '/' + userId, userManagement, options);
    }

    public managementResetPassword(userId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.patch(`${this.managementEndpointUrl}/${userId}/reset-password`, null, options);
    }

    public managementDelete(userId: number): Observable<any> {
        return this.http.delete(this.managementEndpointUrl + '/' + userId);
    }

    public bulkManagement(userManagements: UserManagement[]): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.bulkEndpointUrl, userManagements, options);
    }

    public bulkManagementStatus(executionArn: string): Observable<any> {
        const reqbody = {
            executionArn: executionArn
        };
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.bulkStatusEndpointUrl, reqbody, options);
    }

    public syncEntraID(groupIds: string[]): Observable<any> {
        console.log("sync", this.syncEndpointUrl);
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return this.http.post(this.syncEndpointUrl, groupIds, options);
    }

    public groupMemberAdd(userGroupMember: UserGroupMember): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return this.http.post(this.groupMemberEndpointUrl, userGroupMember, options);
    }

    public groupMemberDelete(userGroupMemberId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return this.http.delete(this.groupMemberEndpointUrl + '/' + userGroupMemberId, options);
    }

    /**
     * [aws][cognito]パスワードリセットコード発行
     *
     * @param email
     */
    public forgotPassword(email: string): Observable<any> {
        const cognitoUser = this.getCognitoUser(email);

        return Observable.create(observer => {
            cognitoUser.forgotPassword({
                onSuccess: () => {
                    observer.next();
                },
                onFailure: err => {
                    observer.error(err);
                },
                inputVerificationCode: () => {
                    observer.next();
                }
            });
        });
    }

    /**
     * [aws][cognito]パスワード再設定
     *
     * @param email
     * @param verificationCode
     * @param password
     */
    public confirmNewPassword(email: string, verificationCode: string, password: string): Observable<any> {
        const cognitoUser = this.getCognitoUser(email);

        return Observable.create(observer => {
            cognitoUser.confirmPassword(verificationCode, password, {
                onSuccess: () => {
                    observer.next();
                },
                onFailure: err => {
                    observer.error(err);
                },
            });
        });
    }

    /**
     * [aws][cognito]新規パスワード設定
     *
     * @param newPasswordUser
     */
     public newPassword(newPasswordUser: NewPasswordUser): Observable<string | true> {
        // Get these details and call
        //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
        let authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        const cognitoUser = this.getCognitoUser(newPasswordUser.username);

        // console.log("UserLoginService: Params set...Authenticating the user");
        // console.log("UserLoginService: config is " + AWS.config);

        return Observable.create(observer => {
            cognitoUser.authenticateUser(authenticationDetails, {
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    delete userAttributes.email_verified;
                    cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                        onSuccess: function (result) {
                            observer.next(true);
                        },
                        onFailure: function (err) {
                            observer.error(err.message);
                        }
                    });
                },
                onSuccess: function () {
                    observer.next(true);
                },
                onFailure: function (err) {
                    observer.error(err.message);
                }
            });
        });
    }

    /**
     * [aws][cognito]パスワード変更
     *
     * @param email
     * @param password
     * @param newPassword
     */
     public changePassword(email: string, password: string, newPassword: string): Observable<string | true> {
        const cognitoUser = this.getCognitoUser(email);
        return Observable.create(observer => {
            cognitoUser.changePassword(password, newPassword, (err, result) => {
                console.log(result);
                if (err) {
                    observer.error(err.message);
                }
                observer.next(true);
                observer.complete();
            });
        });
    }

    /**
     * [aws][cognito]ユーザー更新
     *
     * @param cognitoUser
     * @param email
     * @param password
     * @param name
     * @param value
     */
     public setupMFA(cognitoUser: CognitoUser, email: string, password: string, name: string, value: string): Observable<string | true> {
        let attributeList = [];
        attributeList.push({
            Name: name,
            Value: value
        });
        return Observable.create(observer => {
            cognitoUser.updateAttributes(attributeList, (err, result) => {
                console.log(result);
                if (err)
                    observer.error(err.message);
                const smsMfaSettings = {
                    PreferredMfa : true,
                    Enabled : true
                };
                cognitoUser.setUserMfaPreference(smsMfaSettings, null, function(err, result) {
                    if (err) {
                        console.log(err);
                        observer.error(err.message);
                    }
                    const authenticationData: IAuthenticationDetailsData = {
                        Username: email,
                        Password: password
                    };
                    const authenticationDetails = new AuthenticationDetails(authenticationData);
                    cognitoUser.authenticateUser(authenticationDetails, {
                        onSuccess: result => {
                            console.log("authenticateUser", result);
                        },
                        mfaRequired: function(codeDeliveryDetails) {
                            observer.next(true);
                            observer.complete();
                        },
                        totpRequired: function (secretCode) {
                            observer.next(true);
                            observer.complete();
                        },
                        onFailure: err => {
                            console.error(err);
                            observer.error('User not found.');
                        },
                    });
                });
            });
        });
    }

    /**
     * [aws][cognito]SMS MFAセットアップ
     *
     */
     public setupSmsMFA(mfaType: number = 0): Observable<string | true> {
        const cognitoUser = this.userPool.getCurrentUser();

        return Observable.create(observer => {
            cognitoUser.getSession(function(err, session) {
                if (err) {
                    observer.error(err.message);
                }
                console.log('session validity: ' + session.isValid());
                const smsMfaSettings = {
                    PreferredMfa : true,
                    Enabled : true
                };
                cognitoUser.setUserMfaPreference(smsMfaSettings, null, function(err, result) {
                    if (err) {
                        console.log(err);
                        observer.error(err.message);
                    }
                    console.log('call result ' + result);
                    observer.next(true);
                    observer.complete();
                });
            });
        });
    }

    /**
     * [aws][cognito] MFA TOTPコード発行
     */
     public associateSoftwareToken(): Observable<string | true> {
        // console.log("--------Start TOTP MFA Setup");
        const cognitoUser = this.userPool.getCurrentUser();

        return Observable.create(observer => {
            cognitoUser.getSession(function(err, session) {
                if (err) {
                    observer.error(err.message);
                }
                const totpMfaSettings = {
                    PreferredMfa : true,
                    Enabled : true
                };
                cognitoUser.associateSoftwareToken({
                    associateSecretCode: function(secretCode) {
                        observer.next(secretCode);
                        observer.complete();
                    },
                    onFailure: function(err) {
                        console.log(err);
                        observer.error(err.message);
                    }
                });
            });
        });
    }

    /**
     * [aws][cognito] MFA - SMS認証
     */
    public authSMS(cognitoUser: CognitoUser, code: string): Observable<string | true> {
        const _localStorage = this.localStorage;
        return Observable.create(observer => {
            cognitoUser.sendMFACode(code, {
                onSuccess: function(result) {
                    console.log(result);

                    cognitoUser.getSession(function(err, session) {
                        if (err) observer.error(err.message);
                        else {
                            console.log('setUserMfaPreference call result ' + result);
                            _localStorage.setToken(new Token(session.getIdToken().getJwtToken(), session.getAccessToken().getJwtToken(), session.getRefreshToken().getToken()));
                            observer.next(true);
                            observer.complete();
                        }
                    });
                },
                onFailure: function(err) {
                    console.log(err);
                    observer.error(err.message);
                }
            });
        });
    }

    /**
     * [aws][cognito] MFA - TOTP認証
     */
     public authTOTP(cognitoUser: CognitoUser, code: string): Observable<string | true> {
        const _localStorage = this.localStorage;

        return Observable.create(observer => {
            cognitoUser.sendMFACode(code, {
                onSuccess: function(result) {
                    cognitoUser.getSession(function(err, session) {
                        if (err) observer.error(err.message);
                        else {
                            console.log('setUserMfaPreference ok');
                            console.log(result);
                            _localStorage.setToken(new Token(session.getIdToken().getJwtToken(), session.getAccessToken().getJwtToken(), session.getRefreshToken().getToken()));
                            console.log('setUserMfaPreference call result ' + result);
                            observer.next(true);
                            observer.complete();
                        }
                    });
                },
                onFailure: function(err) {
                    console.log('verifySoftwareToken ng');
                    console.log(err);
                    observer.error(err.message);
                }
            }, 'SOFTWARE_TOKEN_MFA');
        });
    }

    /**
     * [aws][cognito] MFA - SMS認証、設定反映
     */
    public verifyAndEnableSMS(cognitoUser: CognitoUser, code: string): Observable<string | true> {
        const _localStorage = this.localStorage;
        return Observable.create(observer => {
            cognitoUser.sendMFACode(code, {
                onSuccess: function(result) {
                    console.log(result);

                    const smsMfaSettings = {
                        PreferredMfa : true,
                        Enabled : true
                    };
                    cognitoUser.setUserMfaPreference(smsMfaSettings, null, function(err, result) {
                        if (err) {
                            alert(err);
                        }
                        cognitoUser.getSession(function(err, session) {
                            if (err) {
                                observer.error(err.message);
                            }
                            console.log('setUserMfaPreference call result ' + result);
                            _localStorage.setToken(new Token(session.getIdToken().getJwtToken(), session.getAccessToken().getJwtToken(), session.getRefreshToken().getToken()));
                            observer.next(true);
                            observer.complete();
                        });
                    });
                },
                onFailure: function(err) {
                    console.log(err);
                    observer.error(err.message);
                }
            });
        });
    }

    /**
     * [aws][cognito] MFA - TOTP認証、設定反映
     */
     public verifyAndEnableTOTP(code: string): Observable<string | true> {
        const cognitoUser = this.userPool.getCurrentUser();

        const _localStorage = this.localStorage;

        return Observable.create(observer => {
            cognitoUser.getSession(function(err, session) {
                if (err) {
                    observer.error(err.message);
                }
                cognitoUser.verifySoftwareToken(code, 'SoftwareToken', {
                    onSuccess: function(result) {
                        console.log(result);

                        const totpMfaSettings = {
                            PreferredMfa : true,
                            Enabled : true
                        };
                        cognitoUser.setUserMfaPreference(null, totpMfaSettings, function(err, result) {
                            if (err) {
                                alert(err);
                            }
                            console.log('session', session);
                            _localStorage.setToken(new Token(session.getIdToken().getJwtToken(), session.getAccessToken().getJwtToken(), session.getRefreshToken().getToken()));
                            console.log('setUserMfaPreference call result ' + result);
                            observer.next(true);
                            observer.complete();
                        });
                    },
                    onFailure: function(err) {
                        console.log(err);
                        observer.error(err.message);
                    }
                });
            });
        });
    }

    /**
     * [aws][cognito] MFA 認証解除
     */
    public disableMFA(): Observable<string | true> {
        const cognitoUser = this.userPool.getCurrentUser();

        var mfaSettings = {
            PreferredMfa : false,
            Enabled : false
        };

        return Observable.create(observer => {
            cognitoUser.getSession(function(err, session) {
                if (err) {
                    observer.error(err.message);
                }
                cognitoUser.setUserMfaPreference(mfaSettings, mfaSettings, function(err, result) {
                    if (err) {
                        console.error(err);
                        observer.error(err.message);
                    }
                    observer.next(true);
                    observer.complete();
                    console.log('clear MFA call result ' + result);
                });
            });
        });
    }

    // public signup(user: User): Observable<any> {
    //     return new Observable(observer => {
    //         const attributeList = [];
    //         const emailAttribute = new CognitoUserAttribute({ Name: 'email', Value: user.email });
    //         attributeList.push(emailAttribute);

    //         this.userPool.signUp(user.email, user.password, attributeList, [], (error, data) => {
    //             if (error) {
    //                 console.error(error);
    //                 return observer.error(error.message);
    //             }
    //             this.cognitoIdServiceProvider.adminConfirmSignUp({
    //                 Username: user.username,
    //                 UserPoolId: this.userPool.getUserPoolId()
    //             }, (e, d) => this.defaultAdminCallback(e, d, observer));
    //         });
    //     });
    // }

    private getCognitoUser(email: string): CognitoUser {
        const userData: ICognitoUserData = {
            Username: email,
            Pool: this.userPool,
        }
        return new CognitoUser(userData);
    }

    // private defaultAdminCallback(error, data, obs, ok: any = true, no: any = false) {
    //     if (error) {
    //         console.error(error);
    //         obs.next(no);
    //         return;
    //     }
    //     obs.next(ok);
    // }


    public officesMasterDataGet(forceLoad: boolean = false): Observable<OfficesMasterData[]> {
        return (!this.officesMasterData || forceLoad) ? this.http.get(this.officeMasterDatatUrl).pipe(map(result => {
            this.officesMasterData = result["offices"] as OfficesMasterData[];
            return this.officesMasterData;
        })) : observableOf(this.officesMasterData);
    }


    public managementOfficeListPatch(userManagement: UserManagement, userId: number): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        return this.http.patch(this.managementEndpointUrl + '/' + userId, userManagement, options);
    }

    sendCustomNotification(message: string, id?: number): Observable<any> {
        const endpointUrl = environment.apiBaseUrl + '/notification';
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const data: { message: string, resource: string, reception_flow_function_id?: number } = {
            message,
            resource: 'push_notification',
        };
        if (id !== undefined) {
            data.reception_flow_function_id = id;
        }
        return this.http.post(endpointUrl, data, options);
    }

    // access code from google authorization callback
    updateUserByGoogleCalendarAuthorization(code: string, organizationName: string): Observable<any> {
        if (!code) return observableOf(undefined);

        const googleClient = this.getGoogleClient(organizationName);

        const httpParams = new HttpParams()
            .append('client_id', googleClient.ClientId)
            .append('client_secret', googleClient.ClientSecret)
            .append('redirect_uri', environment.googleSettings.RedirectUri)
            .append('grant_type', environment.googleSettings.GrantType)
            .append('code', code);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };

        const endpointUrl = environment.googleSettings.AccessTokenUrl;

        return this.http.post(endpointUrl, httpParams.toString(), httpOptions).pipe(
            switchMap((result: { access_token: string, refresh_token: string }) => {
                const setting: UserSetting = {
                    google_access_token: result.access_token,
                    google_refresh_token: result.refresh_token,
                };
                return this.update(setting);
            })
        );
    }

    getGoogleClient(organizationName: string): { ClientId: string, ClientSecret: string } {
        switch (organizationName) {
            case '株式会社プロトコーポレーション':
            case '株式会社プロトベンチャーズ':
                return environment.googleClientProto;
            case '株式会社カークレド':
                return environment.googleClientCarCredo;
            case '株式会社カーブリックス':
                return environment.googleClientCarBricks;
            default:
                return environment.googleSettings;
        }
    }
}
