<ng-container *ngIf="!informations; else elseBlock">
  <p class="no-info">{{ 'home.information.noInfo' | translate }}</p>
</ng-container>
<ng-template #elseBlock>
  <div class="info-container">
    <mat-selection-list #shoes [multiple]="false" class="info-list">
      <mat-list-option *ngFor="let info of informations" class="info-item" (click)="goToLink(info.url)">
        <span class="info-date">{{ info.date }}</span>
        <ng-container *ngIf="info.url; else notUrlBlock">
          <span class="info-title"><a href="{{ info.url }}" target="_blank" class="info-link">{{ info.title }}</a> <mat-icon class="icon-open-in-new">open_in_new</mat-icon></span>
        </ng-container>
        <ng-template #notUrlBlock>
          <span class="info-title">{{ info.title }}</span>
        </ng-template>
        <span class="info-detail">{{ info.content }}</span>
      </mat-list-option>
    </mat-selection-list>
  </div>
</ng-template>
