<div class="dialog">
    <div mat-dialog-content>
        <p>オプション追加のお願い</p>
        <p class="desctiption">
            受付システム ラクネコのご利用人数 50名プランのみをご契約の場合、<br>
            オプション追加をお願いしております。
        </p>
        <p class="desctiption">
            メニュー「プラン管理」より、オプションを追加をしてください。
        </p>
    </div>
    <div mat-dialog-actions class="content">
        <div class="inner">
            <button mat-raised-button class="button" (click)="close()">閉じる</button>
            <button mat-raised-button class="button" id="option_add_button" color="primary" (click)="goToPlan()">オプション追加</button>
        </div>
    </div>
</div>