import { Component, Inject, Renderer2 } from '@angular/core';
import { LyTheme2, lyl, ThemeVariables } from '@alyle/ui';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { fromEvent } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare var calcSideNav: any;

type Gtag = typeof gtag;
type WindowWithGtag = Window & { gtag: Gtag };

const STYLES = (theme: ThemeVariables) => ({
  $global: lyl `{
    body {
      background-color: #fff
      color: ${theme.text.default}
      font-family: Noto Sans
      margin: 0
      direction: ${theme.direction}
    }
  }`
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  readonly classes = this.theme.addStyleSheet(STYLES);
  private windowWithGtag!: WindowWithGtag;

  title = 'AIForceReception-ManagementSystem';

  constructor(
    private theme: LyTheme2,
    public translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    translate.setDefaultLang(environment.defaultLang);
    translate.use(environment.defaultLang);
    this.iconRegistry.addSvgIcon('pen', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/pen.svg'));
    this.iconRegistry.addSvgIcon('pen_green', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/pen_green.svg'));
    this.iconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/info.svg'));
    this.iconRegistry.addSvgIcon('trash', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/trash.svg'));
    this.windowWithGtag = window as WindowWithGtag;
    this.router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof NavigationEnd)
    ).subscribe((e: RouterEvent) => {
        this.windowWithGtag.gtag('config', environment.gaCode, {
            page_path: e.url
        });
    });
    const gtag = this.renderer.createElement('script');
    gtag.type = 'text/javascript';
    gtag.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaCode}`;
    this.renderer.appendChild(this.document.head, gtag);

    const gtagScript = this.renderer.createElement('script');
    gtagScript.type = 'text/javascript';
    gtagScript.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)};gtag('js', new Date());gtag('config', '${environment.gaCode}');`;
    this.renderer.appendChild(this.document.head, gtagScript);

    this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    this.iconRegistry.addSvgIcon('organization', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/organization.svg'));
  }

  ngAfterViewInit() {

    fromEvent(window, 'DOMContentLoaded').subscribe(e => {
      calcSideNav();
    });
  }

}
