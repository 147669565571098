import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CalendarProvider } from './calendar-provider.enum';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    constructor(
        private http: HttpClient,
    ) { }

    login(calendarProvider: CalendarProvider, organizationName: string) {
        if (calendarProvider === CalendarProvider.Google) {
            const endpointUrl = environment.googleSettings.AuthorizeUrl;
            const googleClient = this.getGoogleClient(organizationName);

            const httpParams = new HttpParams()
                .append('client_id', googleClient.ClientId)
                .append('redirect_uri', environment.googleSettings.RedirectUri)
                .append('scope', environment.googleSettings.Scope)
                .append('response_type', environment.googleSettings.ResponseType)
                .append('approval_prompt', environment.googleSettings.ApprovalPrompt)
                .append('access_type', environment.googleSettings.AccessType)

            location.href = endpointUrl + '?' + httpParams.toString();
        }
    }

    logout() {
        // this.localStorage.deleteToken();
        // const endpointUrl = environment.cognitoSettings.CognitoUrl + '/logout';

        // const httpParams = new HttpParams()
        //     .append('client_id', environment.cognitoSettings.ClientId)
        //     .append('logout_uri', environment.cognitoSettings.LogoutRedirectUrl);

        // location.href = endpointUrl + '?' + httpParams.toString();
    }

    tokenFromGoogleLogin(accessCode: string, organizationName: string): Observable<any> {
        const endpointUrl = environment.googleSettings.AccessTokenUrl;
        const googleClient = this.getGoogleClient(organizationName);
        const httpParams = new HttpParams()
            .append('client_id', googleClient.ClientId)
            .append('client_secret', googleClient.ClientSecret)
            .append('redirect_uri', environment.googleSettings.RedirectUri)
            .append('grant_type', environment.googleSettings.GrantType)
            .append('code', accessCode);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };
        return this.http.post(endpointUrl, httpParams.toString(), httpOptions);
    }

    // WORKAROUND until OAuth verification is finished
    private getGoogleClient(organizationName: string): { ClientId: string, ClientSecret: string } {
        switch (organizationName) {
            case '株式会社プロトコーポレーション':
            case '株式会社プロトベンチャーズ':
                return environment.googleClientProto;
            case '株式会社カークレド':
                return environment.googleClientCarCredo;
            case '株式会社カーブリックス':
                return environment.googleClientCarBricks;
            default:
                return environment.googleSettings;
        }
    }
    // tokenFromRefreshToken(): Observable<any> {
    //     const token = this.localStorage.getToken();

    //     const endpointUrl = environment.cognitoSettings.CognitoUrl + '/oauth2/token';

    //     const httpParams = new HttpParams()
    //         .append('grant_type', 'refresh_token')
    //         .append('client_id', environment.cognitoSettings.ClientId)
    //         .append('refresh_token', token.refreshToken);

    //     const httpHeaders = new HttpHeaders({
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //     });

    //     return this.http.post<any>(endpointUrl, httpParams.toString(), { headers: httpHeaders }).pipe(
    //         tap(
    //             (result) => this.localStorage.setToken(result.id_token, result.access_token, result.refresh_token)
    //         ),
    //     );
    // }
}
