import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { switchMap } from 'rxjs/operators';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from '../../../environments/environment';
import { IdentityProvider } from '../../auth/identity-provider.enum';
import { AuthProvider } from '../../auth/auth-provider.enum';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../core/user';
import { UserService, AuthType } from '../../core/user.service';
import { Country } from '../country.model';
import { countries } from '../country-data-store';
import { CustomValidator } from '../_helper/custom-validator';

const DEFAULT_COUNTRY_CODE = '81';

@Component({
    selector: 'app-mfa-sms',
    templateUrl: './mfa-sms.component.html',
    styleUrls: ['./mfa-sms.component.scss']
})
export class MfaSmsComponent implements OnInit {
    private user: User;
    private userSubscription: Subscription;
    private formChangedSubscription: Subscription;

    private cognitoUser: CognitoUser;

    formGroup: UntypedFormGroup;
    password = new UntypedFormControl('', [Validators.required]);
    code = new UntypedFormControl('', [Validators.required]);

    countries: Country[] = countries;
    selectedCountryCode = 'jp';
    codes = [];

    loading = false;
    submitted = false;
    failed = false;

    enteredPassword = false;
    registeredPhone = false;

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        countries.forEach(country => {
            this.codes.push(country.code);
        });
        this.loadUser();
        this.createFormGroup();
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.formChangedSubscription) {
            this.formChangedSubscription.unsubscribe();
        }
    }

    private createFormGroup() {
        this.formGroup = this.fb.group({
            countries: [countries],
            codes: [this.codes],
            selectCode: [DEFAULT_COUNTRY_CODE],
            phoneNumber: [''],
            password: new UntypedFormControl('', [ Validators.required ]),
            code: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern("^[0-9]*$"),
                Validators.minLength(6)
            ])
        });
        this.formChangedSubscription = this.formGroup.valueChanges.subscribe(val => {
            this.submitted = false;
        });
    }

    private loadUser() {
        this.loading = true;
        this.userSubscription = this.userService.current(true).subscribe(
            user => {
                this.user = user;
                this.loading = false;
            }
        );
    }

    isSelected(country: Country) {
        return country.code === '81';
    }

    changeSelectedCountryCode(value: string): void {
        this.selectedCountryCode = value;
        // this.phoneCode = this.geoService.findCountryCodeByTwoLetterAbbreviation(this.selectedCountryCode);
    }

    get f(): any { return this.formGroup.controls; }
      
    onSubmit() {
        this.failed = false;
        this.submitted = true;

        if (!this.enteredPassword && this.f.password.errors) {
            return;
        } else if (this.enteredPassword && !this.registeredPhone) {
            // 電話番号入力はここでバリデーション
            CustomValidator.phoneNumberValidator(this.formGroup);
        } else if (this.enteredPassword && this.registeredPhone && this.f.code.errors) {
            return;
        }

        this.loading = true;

        if (!this.enteredPassword) {
            this.userService.disableMFA().subscribe(
                result => {
                    this.user.mfa_enabled = 0;
                    this.userService.update(this.user).subscribe(
                        result => {
                            this.userService.login(this.user.email, this.f.password.value).subscribe(
                                result => {
                                    this.cognitoUser = result.cognitoUser;
                                    this.enteredPassword = true;
                                    this.submitted = false;
                                    this.loading = false;
                                },
                                error => {
                                    this.failed = true;
                                    this.loading = false;
                                },
                            );
                        },
                        error => {
                            this.failed = true;
                            this.loading = false;
                        }
                    );
                },
                error => {
                    this.failed = true;
                    this.loading = false;
                },
            );    
        } else if (this.enteredPassword && !this.registeredPhone) {
            if (this.formGroup.controls['phoneNumber'].hasError('phoneNumberInvalid')) {
                this.loading = false;
                return;
            }
            let phoneNumber = this.formGroup.controls.phoneNumber.value;
            phoneNumber = '+' + this.formGroup.controls.selectCode.value + phoneNumber.replace(/\D/g, '').replace(/^0+/, '');
            this.userService.setupMFA(this.cognitoUser, this.user.email, this.f.password.value, 'phone_number', phoneNumber).subscribe(
                () => {
                    this.registeredPhone = true;
                    this.submitted = false;
                    this.loading = false;
                },
                error => {
                    this.failed = true;
                    this.loading = false;
                },
            );    
        } else if (this.enteredPassword && this.registeredPhone) {
            this.userService.verifyAndEnableSMS(this.cognitoUser, this.f.code.value).subscribe(
                () => {
                    this.user.mfa_enabled = 1;
                    this.userService.update(this.user).subscribe(
                        result => {
                            localStorage.setItem('authType', AuthType.SMS);
                            this.router.navigate(['/account_setting/profile']);
                        },
                        error => {
                            this.failed = true;
                            this.loading = false;
                        }
                    );
                },
                error => {
                    this.loading = false;
                    this.failed = true;
                },
            );
        }
    }

    public hasError(controlName: string, errorName: string): boolean {
        return this.formGroup.controls[controlName].hasError(errorName);
    }

    get errorClass() {
        if (this.enteredPassword && !this.registeredPhone) {
            return this.formGroup.controls['phoneNumber'].hasError('phoneNumberInvalid')
            ? 'visibility:visible'
            : 'visibility:hidden';
        } else {
            return this.formGroup.invalid || this.failed
            ? 'visibility:visible'
            : 'visibility:hidden';
        }
    }
}
