import { Injectable } from '@angular/core';

@Injectable()
export class DownloaderService {

    constructor() { }

    downloadBlob(data: any, header: { [key: string]: string }, filename: string) {
        const bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blob = new Blob([bom, data], header);
        if (filename) {
            filename = filename.replace(/ /g, '_');

            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, filename);
            } else {
                const link = document.createElement('a');

                link.href = URL.createObjectURL(blob);

                link.setAttribute('visibility', 'hidden');
                link.download = filename;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}
