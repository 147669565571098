<mat-toolbar *ngIf="isLoggingIn" class="login-header">
  <img height="45" src="../../assets/img/RCP_logo.png"
    srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
</mat-toolbar>
<mat-card *ngIf="isLoggingIn" class="login-card">
  <mat-card-actions>
    <div *ngIf="isLoggingIn">
      ログインしています...<br/>
      Now logging in...
    </div>
  </mat-card-actions>
  <mat-card-footer></mat-card-footer>
</mat-card>

<div id="fill">
  <div *ngIf="!isLoggingIn" id="container" class="artboard scene">
    <div id="primary">
      <div id="primary_outer"></div>
      <div id="primary_inner"></div>
    </div>

    <div id="logo">
      <img src="../../assets/img/RCP_logo.png"
           srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
    </div>

    <div *ngIf="!smsRequired && !totpRequired; else mfaBlock;">
      <div id="inner_vertical_stroke">
        <svg width="1" height="1" style="overflow: visible; position: absolute; left: 0px; top: 0px;">
          <line id="inner_vertical_stroke-line"
                x1="0" y1="0" x2="0" y2="344"
                stroke="#ddd"
                stroke-width="1"
                  />
        </svg>
      </div>
      <div id="label_signin_social">ソーシャルアカウントでログイン</div>
      <div id="label_signin_idpass">ログインIDとパスワードでログイン</div>
      <div id="label_forgot_pass">
        <a href="login/forgot">パスワードをお忘れの方はこちら</a>
      </div>

      <button mat-raised-button class="login-element btn_google" color="primary" [disabled]="loading" (click)="loginWithGoogle()" mdbBtn mdbWavesEffect></button>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div id="input_area" [ngClass]="{ 'is-invalid': submitted && (f.email.errors || f.password.errors) }">
          <div class="form-group">
            <input matInput type="email" id="email" formControlName="email" class="form-control" placeholder="ログインID" required />
          </div>
          <div class="form-group">
            <input matInput type="password" id="password" formControlName="password" class="form-control" placeholder="パスワード" required />
          </div>
        </div>
        <div id="input_error" *ngIf="submitted" class="invalid-feedback">
          <div *ngIf="f.email.errors && f.email.errors.email">ログインIDが正しくありません</div>
          <div *ngIf="!f.password.errors && f.email.errors && f.email.errors.required">ログインIDを入力してください</div>
          <div *ngIf="!f.email.errors && f.password.errors && f.password.errors.required">パスワードを入力してください</div>
          <div *ngIf="(f.email.errors && f.email.errors.required) && (f.password.errors && f.password.errors.required)">入力してください</div>
        </div>
        <div id="error_signin" *ngIf="submitted && loginError">ログインID・パスワードに誤りがあるか、登録されていません</div>
        <div id="error_signin_account_lock" *ngIf="submitted && attemptError">アカウントがロックされています。<br />しばらくしてから再度ログインをお試しください。</div>

        <div id="secondary_section">
          <div id="secondary_button_section">
            <button
              mat-button
              mdbBtn
              mdbWavesEffect
              color="success"
              id="button_input_signin"
              class="login-element"
              [class.spinner]="loading"
              [disabled]="loading"
              (click)="onSubmit()"
            >
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            </button>
            <div id="label_input_signin">ログインする</div>
          </div>
        </div>
      </form>
      <div id="input_area_horizontal_stroke">
        <svg width="1" height="1" style="overflow: visible; position: absolute; left: 0px; top: 0px;">
          <line id="input_area_horizontal_stroke-line"
                x1="0" y1="0" x2="329" y2="0"
                stroke="#ddd"
                stroke-width="1"
                  />
        </svg>
      </div>
    </div>
    <ng-template #mfaBlock>
      <div id="label_login_code">2段階認証コードを入力してください。</div>
      <div id="label_code_send" *ngIf="smsRequired">ご登録の電話番号に送信された認証コードを入力してください。</div>
      <div id="label_code_send" *ngIf="totpRequired">認証アプリに表示されている認証コードを入力してください。</div>
  
      <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div id="input_area_code" [ngClass]="{ 'is-invalid': submitted && (f.code.errors || authFailed) }">
          <div class="form-group">
            <input matInput type="string" id="code" formControlName="code" class="form-control" placeholder="6桁の認証コード" maxlength="6" required />
          </div>
        </div>
        <div id="input_error_code" *ngIf="submitted">
          <div *ngIf="submitted && f.code.errors && !f.code.errors.required">認証コードが正しくありません</div>
          <div *ngIf="submitted && f.code.errors && f.code.errors.required">認証コードを入力してください</div>
          <div *ngIf="submitted && authFailed">認証コードが間違っています</div>
        </div>
  
        <div id="secondary_section_code">
          <div id="secondary_button_section">
            <button
              mat-button
              mdbBtn
              mdbWavesEffect
              type="submit"
              color="success"
              id="button_input_code"
              class="button-element mat-button"
              [class.spinner]="loading"
              [disabled]="loading"
              (click)="onSubmit()"
            >
              <span class="spinner-border spinner-border-sm mr-1"></span>
            </button>
            <div id="label_input_code">送信する</div>
          </div>
        </div>
      </form>  
    </ng-template>
  </div>
</div>
