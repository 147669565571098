import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsageComponent } from './usage/usage.component';
import { InformationComponent } from './information/information.component';
import { FreeTrialInfoDialogComponent } from './dashboard/free-trial-info-dialog.component/free-trial-info-dialog.component';
import { RequestAdditionalOptionsDialogComponent } from './dashboard/request-additional-options-dialog.component/request-additional-options-dialog.component';

@NgModule({
    declarations: [
        DashboardComponent,
        UsageComponent,
        InformationComponent,
        FreeTrialInfoDialogComponent,
        RequestAdditionalOptionsDialogComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatListModule,
        MatGridListModule,
        MatIconModule,
        MatMenuModule,
        MatSnackBarModule,
        SharedModule,
        DashboardRoutingModule,
        MatProgressSpinnerModule
    ]
})
export class DashboardModule { }
