import { Component, OnInit, OnDestroy, ViewChild, HostListener, ChangeDetectorRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from '../../../environments/environment';
import { IdentityProvider } from '../../auth/identity-provider.enum';
import { AuthProvider } from '../../auth/auth-provider.enum';
import { AuthService } from '../../auth/auth.service';
import { UserService, AuthType } from '../../core/user.service';

declare var fillDiv: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    public readonly version = environment.version;

    private loginWithGoogleSubscription: Subscription;
    private formChangedSubscription: Subscription;

    @ViewChild('player', { static: true }) player;

    private cognitoUser: CognitoUser;

    loginForm: UntypedFormGroup;
    email = new UntypedFormControl('', [Validators.required, Validators.email]);
    password = new UntypedFormControl('', [Validators.required]);
    code = new UntypedFormControl('', [Validators.required]);

    isLoggingIn = false;
    loading = false;
    submitted = false;
    loginError = undefined;
    attemptError = undefined;
    authFailed = false;

    smsRequired = false;
    totpRequired = false;

    private requiredError = "New Password Required";
    private passwordExceededError = "Password attempts exceeded";

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private renderer: Renderer2,
        private authService: AuthService,
        private userService: UserService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    ngOnInit() {

        this.loginError = undefined;

        // if auth callback
        const state = history.state;
        if (state && state.callback) {
            const callbackData = state.callback;
            if (callbackData.provider) {
                if (callbackData.provider === AuthProvider.Cognito) {
                    this.loginCallback(callbackData);
                }
            }
        }

        localStorage.removeItem('email');
        localStorage.removeItem('password');

        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            password: new UntypedFormControl('', [Validators.required]),
            code: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern("^[0-9]*$"),
                Validators.minLength(6)
            ])
        });
        this.formChangedSubscription = this.loginForm.valueChanges.subscribe(val => {
            this.submitted = false;
        });

        if (!this.isLoggingIn) {
            fillDiv();
            setTimeout(() => fillDiv());
        }

        this.loadHubSpot();
    }

    ngOnDestroy() {
        if (this.loginWithGoogleSubscription) {
            this.loginWithGoogleSubscription.unsubscribe();
        }
        if (this.formChangedSubscription) {
            this.formChangedSubscription.unsubscribe();
        }
    }

    get environmentName(): string {
        return (environment.name && environment.name !== 'Production') ? '(' + environment.name + ')' : '';
    }

    public hasError(controlName: string, errorName: string): boolean {
        return this.loginForm.controls[controlName].hasError(errorName);
    }

    get f(): any { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.loginError = false;
        this.authFailed = false;

        if (!this.smsRequired && !this.totpRequired) {
            if (this.f.email.errors || this.f.password.errors) return;
            this.login();
        } else if ((this.smsRequired || this.totpRequired)) {
            console.log(this.f.code);
            if (this.f.code.errors) return;
            this.smsRequired ? this.verifySMS() : this.verifyTOTP();
        }
    }

    login(): void {
        this.loading = true;
        this.userService.login(this.f.email.value, this.f.password.value).subscribe(
            result => {
                if (result.cognitoUser) {
                    if (result.authType === AuthType.SMS) {
                        console.log('smsRequired');
                        this.smsRequired = true;
                    } else if (result.authType === AuthType.TOTP) {
                        console.log('totpRequired');
                        this.totpRequired = true;
                    } else {
                        localStorage.setItem('authType', AuthType.IDTOKEN);
                        this.router.navigate(['/dashboard']);
                    }
                    this.submitted = false;
                    this.cognitoUser = result.cognitoUser;
                    // authenticationFlowType: "USER_SRP_AUTH"
                    this.loading = false;
                }
            },
            error => {
                console.log(error);
                if (error == this.requiredError) {
                    localStorage.setItem('email', this.f.email.value);
                    localStorage.setItem('password', this.f.password.value);
                    this.router.navigate(['/login/new']);
                }
                this.loginError = error !== this.passwordExceededError;
                this.attemptError = error === this.passwordExceededError;
                this.loading = false;
            },
        );
    }

    verifySMS(): void {
        this.loading = true;
        this.userService.authSMS(this.cognitoUser, this.f.code.value).subscribe(
            () => {
                localStorage.setItem('authType', AuthType.TOTP);
                this.router.navigate(['/dashboard']);
            },
            error => {
                console.error("verifyAndEnableSms failed: " + error);
                this.loading = false;
                this.authFailed = true;
            },
        );
    }

    verifyTOTP(): void {
        this.loading = true;
        this.userService.authTOTP(this.cognitoUser, this.f.code.value).subscribe(
            () => {
                localStorage.setItem('authType', AuthType.TOTP);
                this.router.navigate(['/dashboard']);
            },
            error => {
                console.error("verifyAndEnableSms failed: " + error);
                this.loading = false;
                this.authFailed = true;
            },
        );
    }

    loginWithGoogle(): void {
        this.authService.login(IdentityProvider.Google);
    }

    private loginCallback(data: { [key: string]: string }) {
        const accessCode: string = data.code;
        if (accessCode && accessCode.length > 0) {
            this.isLoggingIn = true;
            this.loginWithGoogleSubscription = this.authService.tokenFromGoogleLogin(accessCode).pipe(
                switchMap(result => this.userService.current())
            ).subscribe(
                user => {
                    localStorage.setItem('authType', AuthType.GOOGLE);
                    this.router.navigate(['/dashboard']);
                },
                err => {
                    this.authService.logout();
                }
            );
        }
    }

    // HubSpotを読み込む
    private loadHubSpot() {
        console.log("loadHubSpot", "login");
        const hs = this.document.getElementById('hs-script-loader');
        const hsContainer = this.document.getElementById('hubspot-messages-iframe-container');
        let hsContainerStyle = 'initial';

        if (!hs) {
            console.log('show hub spot');
            /* Start of Generate HubSpot Embed Code */
            const hubspotScript = this.renderer.createElement('script');
            hubspotScript.type = 'text/javascript';
            hubspotScript.id = 'hs-script-loader';
            hubspotScript.src = '//js.hs-scripts.com/23839271.js';
            hubspotScript.async = true;
            hubspotScript.defer = true;
            this.renderer.appendChild(this.document.body, hubspotScript);
            /* End of Generate HubSpot Embed Code */
        }
        
        if (hsContainer) hsContainer.style.setProperty('display', hsContainerStyle, 'important');
    }
}
