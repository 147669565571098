import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class NavigationService {

    private organizationChangesNotifySource = new Subject();

    organizationChangesNotify$ = this.organizationChangesNotifySource.asObservable();

    notifyOrganizationChanges() {
        this.organizationChangesNotifySource.next();
    }

}
