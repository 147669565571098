<div class="free-trial-dialog">
    <div mat-dialog-content>
        <p>無料トライアルのご利用ありがとうございます。</p>
        <p class="desctiption">
            クレジットカード情報を登録すると設定を引き継いで本契約に移行することができます。
        </p>
        <p class="desctiption">
            登録後もトライアル終了日時までは無料でお使いいただけます。<br>
            トライアルのキャンセルも可能で、終了日時までにキャンセルした場合、費用は一切発生しません。
        </p>
        <p class="desctiption">
            トライアル終了日時：{{data.trialEnd}} 23:59:59
        </p>
    </div>
    <div mat-dialog-actions class="content">
        <div class="inner">
            <button mat-raised-button class="button" (click)="close()">今はしない</button>
            <button mat-raised-button class="button" id="plan_button" color="primary" (click)="goToPlan()">登録画面に進む</button>
        </div>
    </div>
</div>
