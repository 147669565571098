<div id="fill">
  <div id="container" class="artboard scene">
    <div id="primary">
      <div id="primary_outer"></div>
      <div id="primary_inner"></div>
    </div>

    <div id="logo">
      <img src="../../assets/img/RCP_logo.png"
           srcset="../../assets/img/RCP_logo.png 1x, ../../assets/img/RCP_logo.png 2x">
    </div>

    <div id="label_new_password">新しいパスワードを入力してください。</div>

    <div class="label_password">新しいパスワード</div>
    <div class="label_confirm_password">新しいパスワード（確認）</div>
    <div class="label_annotation1">※パスワードは大文字・小文字・数字それぞれを組み合わせてください</div>
    <div class="label_annotation2">※パスワードは8文字以上入力する必要があります</div>

    <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
      <div id="input_password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
        <input matInput type="password" id="password" formControlName="password" class="form-control" placeholder="" />
        <table *ngIf="submitted && f.password.errors" id="input_error_table" class="invalid-feedback">
          <tr>
            <td>
              <span *ngIf="f.password.errors.required">パスワードを入力してください</span>
              <span *ngIf="f.password.errors.minlength">8文字以上入力してください<br /></span>
              <span *ngIf="f.password.errors.hasCapitalCase">大文字を1文字以上含めてください<br /></span>
              <span *ngIf="f.password.errors.hasSmallCase">小文字を1文字以上含めてください<br /></span>
              <span *ngIf="f.password.errors.hasNumber">数字を1文字以上含めてください</span>
            </td>
          </tr>
        </table>
      </div>
      <div id="input_confirm_password" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
        <input matInput type="password" id="confirm_password" formControlName="confirmPassword" class="form-control" placeholder="" />
        <div *ngIf="submitted && f.confirmPassword.errors" id="input_error" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">確認用パスワードを入力してください</div>
          <div *ngIf="f.confirmPassword.errors.NoPassswordMatch">パスワードが一致しません</div>
        </div>
      </div>
      <div id="change_password_error" *ngIf="submitted && failed">登録できませんでした。時間をおいて再試行してください。</div>

      <div id="secondary_section">
        <div id="secondary_button_section">
          <button mat-button mat-raised-button mdbBtn mdbWavesEffect
            color="success"
            id="button_input_new"
            class="button-element mat-button"
            [class.spinner]="loading"
            [disabled]="loading" 
            (click)="onSubmit()"
          >
            <span class="spinner-border spinner-border-sm mr-1"></span>
          </button>
          <div id="label_input_new">パスワードを変更する</div>
        </div>
      </div>
    </form>
    <div id="label_back_login">
      <a href="login">キャンセルする</a>
    </div>
  </div>
</div>
