import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IdentityProvider } from '../../auth/identity-provider.enum';
import { AuthProvider } from '../../auth/auth-provider.enum';
import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../core/user.service';

declare var fillDiv: any;

@Component({
    selector: 'app-mfa-select',
    templateUrl: './mfa-select.component.html',
    styleUrls: ['./mfa-select.component.scss']
})
export class MfaSelectComponent implements OnInit, OnDestroy {
    public readonly version = environment.version;

    isLoggingIn = false;
    loading = false;
    submitted = false;
    loginError = undefined;

    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService
    ) {
    }

    ngOnInit() {}

    ngOnDestroy() {}

    onClickSms() {
        this.router.navigate(['/mfa/sms']);
    }

    onClickApp() {
        this.router.navigate(['/mfa/app']);
    }
}
