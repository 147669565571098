import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { throwIfAlreadyLoaded } from './module-import.guard';
import { CalendarService } from './calendar.service';
import { DownloaderService } from './downloader.service';
import { DataConverterService } from './data-converter.service';
import { UserService } from './user.service';
import { OrganizationService } from './organization.service';
import { SlackNotificationService } from './slack-notification.service';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        CalendarService,
        DataConverterService,
        DownloaderService,
        UserService,
        OrganizationService,
        SlackNotificationService,
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
