import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyTypographyModule } from '@alyle/ui/typography';
import { SlackAccountComponent } from './slack-account/slack-account.component';
import { HangoutsChatAccountComponent } from './hangouts-chat-account/hangouts-chat-account.component';
import { ChatWorkAccountComponent } from './chatwork-account/chatwork-account.component';
import { GoogleAccountComponent } from './google-account/google-account.component';
import { MicrosoftEntraIdAccountComponent } from './microsoft-entra-id-account/microsoft-entra-id-account.component';
import { AkerunAccountComponent } from './akerun-account/akerun-account.component';
import { PhotoEditorComponent } from './photo-editor/photo-editor.component';
import { ViewModeDirective } from './editable/view-mode.directive';
import { EditModeDirective } from './editable/edit-mode.directive';
import { EditableComponent } from './editable/editable.component';
import { EditableOnEnterDirective } from './editable/edit-on-enter.directive';
import { FocusableDirective } from './editable/focusable.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SmsComponent } from './sms/sms.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { EmailComponent } from './email/email.component';

import { MicrosoftTeamsAccountComponent } from './microsoft-teams-account/microsoft-teams-account.component';
import { LineWorksAccountComponent } from './lineworks-account/lineworks-account.component';
import { LineWorksV2AccountComponent } from './lineworks-v2-account/lineworks-v2-account.component';
import { UserGroupNotificationComponent } from './user-group-notification/user-group-notification.component';
import { CompletionSettingComponent } from './completion-setting/completion-setting.component';

import { TranslateModule } from '@ngx-translate/core';

import { StylePaginatorDirective } from '../shared/style-paginator/style-paginator.directive';
import { PhoneInterphoneNumberComponent } from './phone-interphone-number/phone-interphone-number.component';
import { PhoneExtensionAddressComponent } from './phone-extension-address/phone-extension-address.component';
import { MobileInterphoneCallingUserComponent } from './mobile-interphone-calling-user/mobile-interphone-calling-user.component';

import { ContractTooltipComponent } from './contract-tooltip/contract-tooltip.component';

@NgModule({
    declarations: [
        SlackAccountComponent,
        HangoutsChatAccountComponent,
        ChatWorkAccountComponent,
        GoogleAccountComponent,
        MicrosoftEntraIdAccountComponent,
        AkerunAccountComponent,
        SmsComponent,
        PhoneNumberComponent,
        PhoneInterphoneNumberComponent,
        PhotoEditorComponent,
        ViewModeDirective,
        EditModeDirective,
        EditableComponent,
        EditableOnEnterDirective,
        FocusableDirective,
        ConfirmDialogComponent,
        MessageDialogComponent,
        LoadingDialogComponent,
        MicrosoftTeamsAccountComponent,
        LineWorksAccountComponent,
        LineWorksV2AccountComponent,
        UserGroupNotificationComponent,
        EmailComponent,
        CompletionSettingComponent,
        StylePaginatorDirective,
        PhoneExtensionAddressComponent,
        MobileInterphoneCallingUserComponent,
        ContractTooltipComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        LyImageCropperModule,
        LyButtonModule,
        LyIconModule,
        LyTypographyModule,
        TranslateModule,
    ],
    exports: [
        SlackAccountComponent,
        HangoutsChatAccountComponent,
        ChatWorkAccountComponent,
        MicrosoftTeamsAccountComponent,
        LineWorksAccountComponent,
        LineWorksV2AccountComponent,
        GoogleAccountComponent,
        MicrosoftEntraIdAccountComponent,
        AkerunAccountComponent,
        SmsComponent,
        PhoneNumberComponent,
        PhoneInterphoneNumberComponent,
        PhoneExtensionAddressComponent,
        MobileInterphoneCallingUserComponent,
        PhotoEditorComponent,
        ViewModeDirective,
        EditModeDirective,
        EditableComponent,
        EditableOnEnterDirective,
        FocusableDirective,
        ConfirmDialogComponent,
        MessageDialogComponent,
        LoadingDialogComponent,
        UserGroupNotificationComponent,
        EmailComponent,
        CompletionSettingComponent,
        TranslateModule,
        StylePaginatorDirective,
        ContractTooltipComponent,
    ]
})
export class SharedModule { }
